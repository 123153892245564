const AUTH_STORAGE_KEY = 'authData';

export const getAuthData = () => {
  return JSON.parse(localStorage.getItem(AUTH_STORAGE_KEY) || '{}');
};

export const saveAuthData = (accessToken: string, refreshToken: string) => {
  localStorage.setItem(
    AUTH_STORAGE_KEY,
    JSON.stringify({ accessToken, refreshToken })
  );
};

export const clearAuthData = () => {
  localStorage.removeItem(AUTH_STORAGE_KEY);
};

