import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import ISubscriptionCard from 'components/Subscription/types/SubscriptionCard.interface';
import { Button } from 'components/Button/Button';
import { MdCheckCircleOutline } from 'react-icons/md';
import styles from './SubscriptionCard.module.scss';

interface ISubscriptionCardProps {
  card: ISubscriptionCard;
}

const SubscriptionCard: FC<ISubscriptionCardProps> = ({ card }) => {
  const navigate = useNavigate();

  return (
    <div className={styles.subscription_card}>
      {card.isRecommended && (
        <div className={styles.subscription_card__badge}>Recommended</div>
      )}

      <div className={styles.subscription_card__header}>
        <h3>{card.title}</h3>
        <p>
          <span>${card.price}</span>
          <span>/{card.interval}</span>
        </p>
      </div>
      <Button title="Select Plan" onClick={() => navigate('/checkout')} />
      <ul className={styles.subscription_card__features}>
        {card.features.map((feature, index) => (
          <li key={index}>
            <MdCheckCircleOutline />
            {feature}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SubscriptionCard;

