import React, { useRef, useState } from 'react';
import { MdKeyboardArrowDown } from 'react-icons/md';

import { useAuth } from 'store/auth';
import useClickOutside from 'hooks/useClickOutside';
import styles from './UserMenu.module.scss';
import { useNavigate } from 'react-router-dom';

const UserMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();
  const { logout } = useAuth();

  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  useClickOutside(dropdownRef, () => setIsOpen(false), buttonRef);

  const toggleDropdown = () => setIsOpen((currentState) => !currentState);

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  return (
    <div className={styles['user-menu']} onClick={toggleDropdown} ref={buttonRef}>
      <span>JS</span>
      <button>
        <MdKeyboardArrowDown />
      </button>

      {isOpen && (
        <div className={styles.dropdown} ref={dropdownRef}>
          <button>Your Website</button>
          <button>Settings</button>
          <button onClick={handleLogout}>Log Out</button>
        </div>
      )}
    </div>
  );
};

export default UserMenu;

