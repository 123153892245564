import React, { useRef, useState } from 'react';

import {
  MdKeyboardArrowDown,
  MdOutlineDesktopWindows,
  MdOutlineSmartphone,
  MdOutlineTabletAndroid,
} from 'react-icons/md';

import styles from './DevicePicker.module.scss';
import useClickOutside from 'hooks/useClickOutside';

type TDevice = 'desktop' | 'tablet' | 'mobile';

const DEVICES = {
  desktop: <MdOutlineDesktopWindows />,
  tablet: <MdOutlineTabletAndroid />,
  mobile: <MdOutlineSmartphone />,
};

const DevicePicker = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState<TDevice>('desktop');

  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  useClickOutside(dropdownRef, () => setIsOpen(false), buttonRef);

  const toggleDropdown = () => setIsOpen((currentState) => !currentState);

  const handleDeviceChange = (device: TDevice) => {
    setSelectedDevice(device);
    setIsOpen(false);
  };

  return (
    <div className={styles['device-picker']}>
      <div
        className={styles['device-picker__container']}
        onClick={toggleDropdown}
        ref={buttonRef}
      >
        {DEVICES[selectedDevice]}
        <MdKeyboardArrowDown />
      </div>

      {isOpen && (
        <div className={styles.dropdown} ref={dropdownRef}>
          {Object.keys(DEVICES).map((device) => (
            <button
              key={device}
              onClick={() => handleDeviceChange(device as TDevice)}
            >
              {DEVICES[device as TDevice]}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default DevicePicker;

