import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { Button } from 'components/Button/Button';
import Header from './components/Header';

import styles from './AccountLayout.module.scss';

interface IAccountLayoutProps {
  isEditor?: boolean;
}

const AccountLayout: FC<IAccountLayoutProps> = ({ isEditor = false }) => {
  return (
    <div className={styles['account-layout']}>
      <div className={styles.upgrade}>
        <p>Get your own domain and launch your website right now!</p>
        <Button title="Upgrade" className={styles.upgrade__button} />
      </div>
      <Header isEditor={isEditor} />
      <main>
        <Outlet />
      </main>
    </div>
  );
};

export default AccountLayout;

