import React from 'react'
import { useState } from 'react'
import './faq.scss'

const content = [
    {
        title: `I'm a dentist with no tech experience.
    Can I still build my website?`, body: `Absolutely! Idesn is specifically designed for
    individuals who don't have any coding
    experience or technical background. With Idesn,
    all you need to do is answer a series of
    questions, and our AIpowered software will take
    care of the rest, creating a professional
    website for your dental practice in just 5
    minutes. Our platform emphasizes simplicity and
    efficiency, providing all the necessary tools
    for small business owners like yourself to
    establish an online presence effortlessly
    without any technical hassles.`},
    {
        title: `Can I have a personalized domain for my
        website?`, body: `Definitely! We highly recommend using a custom
        domain. While Idesn allows you to publish your
        website under a free subdomain we provide,
        having a unique domain for your website enhances
        your brand's trustworthiness and identity.
        Idesn offers a custom site domain free for one
        year to all our Website annual users. Connecting
        a domain to your Idesn website is a breeze.
        Simply follow the step-by-step instructions on
        the Idesn dashboard, and your site will be
        accessible via your custom domain within a few
        hours.`},
    {
        title: `Can Idesn host my dental website for free?`, body: ` Certainly! When you publish a site with Idesn,
        you'll receive professional, fast, and
        reliable web hosting with server locations
        around the globe. The best part? Web hosting is
        entirely free. Whether your website caters to a
        local or global audience, it will always be
        accessible and load quickly. As your business
        expands, Idesn's professional hosting scales
        to provide the appropriate support for your
        online services. Additionally, all Idesn
        websites come with top-notch security, ensuring
        customer safety with a free SSL certificate for
        every site.`},
    {
        title: `Will my website be easy to find on search
        engines?`, body: ` Definitely! We recognize the importance of SEO
        for helping your dental website rank high on
        search engine results pages. With Idesn, every
        website is automatically search engine optimized
        (SEO) by default. Furthermore, Idesn ensures
        rapid page loading speeds, providing an optimal
        user experience for your website or online
        store, and keeping potential clients engaged.
        Our website builder is specifically designed to
        boost your dental practice's online
        visibility on search engines, using a
        combination of SEO tools and templates that are
        search engine-friendly from the very beginning.`},
    {
        title: `Will my website look good on smartphones and
        tablets?`, body: `Every site created using the Idesn website
        builder will be mobile responsive by default,
        ensuring that it functions seamlessly on any
        device, not just mobile phones. We understand
        the importance for small business owners to
        reach their audience where they're most
        active - which is increasingly on mobile devices
        - so we've prioritized making sure our
        websites are mobileoptimized for an exceptional
        user experience. While most of the mobile
        responsiveness is handled automatically by our
        platform, you can also switch to mobile view
        during editing to confirm that your website
        appears exactly as you want it to. This design
        feature is crucial for helping you create an
        attractive site, generate leads, and drive
        traffic.`},
]

export const Faq = () => {
    const [active, setActive] = useState('');

    const handleClick = (title: string) => {
        setActive(title);

        if (active === title) {
            setActive('');
        }
    };


    return (
        <section id="faq" className="o-section o-faq">
            <div className="container">
                <h2 style={{ textAlign: 'center' }}>
                    Frequently Asked <em>Questions</em>
                </h2>
                <div className="m-faq-list">
                    {content.map(({ title, body }) => (
                        <div key={title} className="m-faq-item">
                            <div className={`title ${active === title && 'active'}`} onClick={() => handleClick(title)}>
                                <span>
                                    {title}
                                </span>
                            </div>
                            {active === title &&
                                <div className="desc">
                                    {body}
                                </div>
                            }
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}
