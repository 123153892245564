import React from 'react'
import { Link as LinkScroll } from 'react-scroll'
import PropTypes from 'prop-types'
import './burger-menu.scss';
import { ButtonLink } from 'components/Button/ButtonLink';
import { useAuth } from 'store/auth';

const BurgerMenu = ({ closeMenu }: { closeMenu: () => void }) => {
    const { isLoggedIn, logout } = useAuth();

    const handleLogout = () => {
        logout();
        closeMenu();
    }

    return (
        <>
            <div className="m-burger-menu">
                <div className="container">
                    <ul className="m-menu">
                        <li>
                            <LinkScroll
                                activeClass="active"
                                to="hero"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                                onClick={closeMenu}
                            >
                                Home
                            </LinkScroll>
                        </li>
                        <li>
                            <LinkScroll
                                activeClass="active"
                                to="process"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                                onClick={closeMenu}
                            >
                                How It Works
                            </LinkScroll>
                        </li>
                        <li>
                            <LinkScroll
                                activeClass="active"
                                to="features"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                                onClick={closeMenu}
                            >
                                Features
                            </LinkScroll>
                        </li>
                        <li>
                            <LinkScroll
                                activeClass="active"
                                to="testimonials"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                                onClick={closeMenu}
                            >
                                Testimonials
                            </LinkScroll>
                        </li>
                        <li>
                            <LinkScroll
                                activeClass="active"
                                to="faq"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                                onClick={closeMenu}
                            >
                                FAQ
                            </LinkScroll>
                        </li>
                    </ul>
                    {isLoggedIn ? (
                        <button
                            className="a-btn transparent"
                            onClick={handleLogout}
                            color="transparent"
                        >
                            Exit
                        </button>
                    ) : (
                        <ButtonLink to="/login" color="white" title="Log In" />
                    )}
                    <ButtonLink
                        color="blue"
                        title="Get Started For Free"
                        to="/step1"
                    />
                </div>
            </div>
        </>
    )
}

export default BurgerMenu

BurgerMenu.propTypes = {
    closeMenu: PropTypes.func,
}
