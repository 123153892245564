import React, { FC } from 'react';
import { Navigate } from 'react-router-dom';

import { useAuth } from 'store/auth';

interface IRequireAuthProps {
  Component: FC;
};

const RequireAuth: FC<IRequireAuthProps> = ({ Component }) => {
  const { isLoggedIn } = useAuth();

  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }

  return <Component />;
}

export default RequireAuth;
