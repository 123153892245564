import React, { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';

import styles from './Section.module.scss';

type TSectionProps = PropsWithChildren<{
  title: string;
  isActive: boolean;
}>;

const Section: FC<TSectionProps> = ({ isActive, title, children }) => {
  return (
    <section
      className={classNames(styles.section, {
        [styles.active]: isActive,
      })}
    >
      <h3>{title}</h3>
      {children}
    </section>
  );
};

export default Section;
