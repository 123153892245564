import React from 'react';

const Team = ({
  name,
  title,
  photo,
  color,
}: {
  name: string;
  title: string;
  photo: string;
  color: string;
}) => {
  return (
    <div className="steps__template__section__team__wrapper__img">
      <img src={`${photo !== '' ? photo : '/assets/example.png'}`} alt="" />
      <h4>{name}</h4>
      <p className={color}>{title}</p>
    </div>
  );
};

export default Team;
