import { Input } from 'components/UI/Input'
import React from 'react'

export const ServiceInput = ({ number, onChange, id, name }: { number: number; onChange: (e: any) => void ; id: string; name: string}) => {
    return (
        <div>
            <h3>Service {number}</h3>
            <Input onChange={(e) => onChange(e)} placeholder='Enter your service' id={id} value={name}/>
        </div>
    )
}
