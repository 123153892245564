import React, { useState } from 'react'
import { useFirstStepStore } from 'store/steps';
import { ButtonLink } from 'components/Button/ButtonLink';

import './try-it.scss'

export const TryIt = () => {
    const setGlobalName = useFirstStepStore((state) => state.setFirstStep);
    const [name, setName] = useState('');
    const [slogan, setSlogan] = useState('');

    const submitStep = () => {
        setGlobalName(name, slogan);
    }

    return (
        <>
            <div className="o-tryit">
                <div className="container">
                    <div className="o-tryit-row">
                        <div className="right">
                            <h2>
                                Try It Out <em>Now</em>
                            </h2>
                            <div className="input">
                                <h3>What is the name of your dental clinic?</h3>
                                <input
                                    maxLength={50}
                                    onChange={(event) => setName(event.target.value)}
                                    type="text"
                                    placeholder="Enter Your Clinic Name"
                                />
                            </div>
                            <div className="input">
                                <h3>
                                    Does your clinic have a tagline or slogan?
                                </h3>
                                <input
                                    maxLength={100}
                                    onChange={(event) => setSlogan(event.target.value)}
                                    type="text"
                                    placeholder="Enter Your Tagline or slogan"
                                />
                            </div>
                            <ButtonLink onClick={submitStep} to='/step1' title="Next" />
                        </div>
                        <div className="left">
                            <div className="img">
                                <img alt='Example' src="/assets/example.png" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

