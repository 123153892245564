import { create } from 'zustand';

interface EditorState {
  isEditing: boolean;
  toggleEditing: () => void;
}

export const useEditor = create<EditorState>((set) => ({
  isEditing: false,
  toggleEditing: () => set((state) => ({ isEditing: !state.isEditing })),
}));

