import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { Colors } from 'types/steps';
import './color-picker.scss';

const colors = [
  {
    main: '#121F38',
    primary: '#17A8D8',
    name: 'color1',
  },
  {
    main: '#252B42',
    primary: '#6184F4',
    name: 'color2',
  },
  {
    main: '#1C2E2D',
    primary: '#048ABF',
    name: 'color3',
  },
] as Array<Colors>;

export const ColorPicker = ({
  setColor,
}: {
  setColor: Dispatch<SetStateAction<Colors>>;
}) => {
  const [firstColor] = colors;
  const [pickedColor, setPickedColor] = useState<string>(firstColor.main);
  const isPicked = (color: any) => pickedColor === color;

  useEffect(() => {
    if (colors)
      setColor(colors.find((color) => color.main === pickedColor) || colors[0]);
  }, [setColor, pickedColor]);

  return (
    <div className="color-picker">
      {colors.map((color) => (
        <div
          onClick={() => setPickedColor(color.main)}
          className={`color-picker__container ${
            isPicked(color.main) ? 'color-picker__picked' : ''
          }`}
          key={color.main}
        >
          <div
            style={{ background: color.main }}
            className="color-picker__color"
          />
          <div
            style={{ background: color.primary }}
            className="color-picker__color"
          />
        </div>
      ))}
    </div>
  );
};

