import React from 'react'
import { useEffect, useState, useRef } from 'react';
import './progress-bar.scss'

export const ProgressBar = ({ step }: { step: number }) => {

    const barRef = useRef<HTMLDivElement | null>(null);
    const [width, setWidth] = useState('');

    useEffect(() => {
        if (barRef.current) {
            setWidth(barRef.current.clientWidth / 11 * step + 'px')
        }
    }, [step])


    return (
        <div ref={barRef} className='progress-bar'>
            <span style={{ width: width ?? '0px' }} className="progress-bar__line"></span>
        </div>
    )
}
