import React from 'react'
import { Link as LinkScroll } from 'react-scroll'
import { useNavigate, useLocation } from 'react-router-dom'

const Menu = () => {
    const navigate = useNavigate()
    const location = useLocation()
    function handleScrollTo(sectionId: string) {
        if (location.pathname !== '/') {
            navigate('/')
            setTimeout(() => {
                const element = document.getElementById(sectionId)
                if (element) {
                    window.scrollTo({
                        top: element.offsetTop - 120,
                        behavior: 'smooth',
                    })
                }
            }, 400)
        }
    }
    return (
        <>
            <ul className="m-menu">
                <li>
                    <LinkScroll
                        activeClass="active"
                        to="hero"
                        spy={true}
                        smooth={true}
                        offset={-120}
                        duration={500}
                        onClick={() => handleScrollTo('hero')}
                    >
                        Home
                    </LinkScroll>
                </li>
                <li>
                    <LinkScroll
                        activeClass="active"
                        to="process"
                        spy={true}
                        smooth={true}
                        offset={-120}
                        duration={500}
                        onClick={() => handleScrollTo('process')}
                    >
                        How It Works
                    </LinkScroll>
                </li>
                <li>
                    <LinkScroll
                        activeClass="active"
                        to="features"
                        spy={true}
                        smooth={true}
                        offset={-120}
                        duration={500}
                        onClick={() => handleScrollTo('features')}
                    >
                        Features
                    </LinkScroll>
                </li>
                <li>
                    <LinkScroll
                        activeClass="active"
                        to="testimonials"
                        spy={true}
                        smooth={true}
                        offset={-120}
                        duration={500}
                        onClick={() => handleScrollTo('testimonials')}
                    >
                        Testimonials
                    </LinkScroll>
                </li>
                <li>
                    <LinkScroll
                        activeClass="active"
                        to="faq"
                        spy={true}
                        smooth={true}
                        offset={-120}
                        duration={500}
                        onClick={() => handleScrollTo('faq')}
                    >
                        FAQ
                    </LinkScroll>
                </li>
            </ul>
        </>
    )
}

export default Menu
