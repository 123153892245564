export const annualSubscriptionPlans = [
  {
    title: 'Basic',
    price: 15,
    interval: 'mo',
    features: [
      'Custom domain connection (domain not included for monthly subscription)',
      'SSL security',
      '5 GB storage',
      'Unlimited bandwidth',
      '5 email accounts',
      'Basic SEO tools',
      'AI-powered image and copy generation',
      'Standard customer support',
      'Website hosting',
    ],
  },
  {
    title: 'Premium',
    price: 30,
    interval: 'mo',
    features: [
      'All features in the Basic Plan',
      '15 GB storage',
      'Personalized support within 4 hours',
      'Advanced SEO tools',
      'Appointment scheduling integration',
      'AI-powered image and copy generation for advanced customization',
    ],
    isRecommended: true,
  },
];

export const monthToMonthSubscriptionPlans = [
  {
    title: 'Basic',
    price: 20,
    interval: 'mo',
    features: [
      'Custom domain connection (domain not included for monthly subscription)',
      'SSL security',
      '5 GB storage',
      'Unlimited bandwidth',
      '5 email accounts',
      'Basic SEO tools',
      'AI-powered image and copy generation',
      'Standard customer support',
      'Website hosting',
    ],
  },
  {
    title: 'Premium',
    price: 40,
    interval: 'mo',
    features: [
      'All features in the Basic Plan',
      '15 GB storage',
      'Personalized support within 4 hours',
      'Advanced SEO tools',
      'Appointment scheduling integration',
      'AI-powered image and copy generation for advanced customization',
    ],
    isRecommended: true,
  },
];
