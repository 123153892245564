import React from "react";

import Logo from "components/UI/Logo";
import styles from './MinimalHeader.module.scss';

const MinimalHeader = () => {
    return (
        <header className={styles.minimal_header}>
            <Logo />
        </header>
    )
}

export default MinimalHeader;
