import { Button } from 'components/Button/Button';
import { ButtonLink } from 'components/Button/ButtonLink';
import { ProgressBar } from 'components/ProgressBar/ProgressBar';
import { ServiceInput } from 'components/ServiceInput/ServiceInput';
import React, { useEffect, useState } from 'react'
import { useFourthStepStore } from 'store/steps';


const Step4 = () => {
    const setStateServices = useFourthStepStore().setFourthStep
    const inputsValue = useFourthStepStore().getServices
    const [services, setServices] = useState(inputsValue);

    useEffect(() => {
        setStateServices(services)
    }, [services])

    const addService = () => setServices((prev => {
        return [...prev, {name: '', description: ['']}]
    }))

    const onChange = (e: any) => {
        const { id, value } = e.target;
        console.log(id, value);
        
        setServices((prevServices) => {
            const updatedServices = [...prevServices];
            updatedServices[id].name = value;
            return updatedServices;
        });
    }

    return (
        <>
            <div className='steps__container'>
                <h2 className='steps__heading'>What dental services does your clinic offer?</h2>
                <h3 className='steps__label'>E.g., general dentistry, cosmetic dentistry, orthodontics, pediatric dentistry.</h3>
                <div className='steps__services'>
                    {services.map((obj, index) => <ServiceInput key={index} number={index + 1} onChange={(e: any) => onChange(e)} id={index.toString()} name={obj.name}/> )}
                </div>
                <Button onClick={addService} isOutlined clear title='+ Add more services' />
            </div>
            <ProgressBar step={4} />
            <div className='steps__multi-container'>
                <ButtonLink underline isOutlined to='/step3' title='Back' />
                <ButtonLink isOutlined to="/step5" title='Next' />
            </div>
        </>
    )
}

export default Step4;