import React, { FC } from 'react';

import Section from '../Section';
import styles from './BillingHistorySection.module.scss';
import { ETabs } from 'components/Dashboard/types/Tabs.enum';
import {
  MdOutlineEmail,
  MdOutlineFileDownload,
  MdOutlineRemoveRedEye,
} from 'react-icons/md';

interface IBillingHistorySectionProps {
  isActive: boolean;
}

const mockData = [
  {
    date: 'Mar 27, 2023',
    orderNumber: 'ABC1234567890US',
    plan: 'Premium',
    amount: '$40.00',
  },
  {
    date: 'Mar 27, 2023',
    orderNumber: 'ABC1234567890US',
    plan: 'Premium',
    amount: '$40.00',
  },
  {
    date: 'Mar 27, 2023',
    orderNumber: 'ABC1234567890US',
    plan: 'Premium',
    amount: '$40.00',
  },
];

const BillingHistorySection: FC<IBillingHistorySectionProps> = ({
  isActive,
}) => {
  return (
    <Section title={ETabs.BILLING_HISTORY} isActive={isActive}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Date</th>
            <th>Order Number</th>
            <th>Plan</th>
            <th>Amount</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {mockData.map((item, index) => (
            <tr key={index}>
              <td>{item.date}</td>
              <td>{item.orderNumber}</td>
              <td>{item.plan}</td>
              <td>{item.amount}</td>
              <td className={styles.table__actions}>
                <button className={styles.actionButton}>
                  <MdOutlineRemoveRedEye />
                </button>
                <button className={styles.actionButton}>
                  <MdOutlineFileDownload />
                </button>
                <button className={styles.actionButton}>
                  <MdOutlineEmail />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <ul className={styles.mobileTable}>
        {mockData.map((item, index) => (
          <li key={index}>
            <div className={styles.mobileTable__header}>
              <span>{item.date}</span>
              <span>{item.plan}</span>
            </div>
            <span className={styles['mobileTable__order-number']}>
              {item.orderNumber}
            </span>
            <span className={styles.mobileTable__amount}>{item.amount}</span>
            <div className={styles.mobileTable__actions}>
              <button className={styles.actionButton}>
                <MdOutlineRemoveRedEye />
              </button>
              <button className={styles.actionButton}>
                <MdOutlineFileDownload />
              </button>
              <button className={styles.actionButton}>
                <MdOutlineEmail />
              </button>
            </div>
          </li>
        ))}
      </ul>
      {/* <p>
          Once you make a purchase, you'll find your payment history and
          invoices from Idesn right here.
        </p> */}
    </Section>
  );
};

export default BillingHistorySection;

