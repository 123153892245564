import React from 'react';
import { Link as ReactLink } from 'react-router-dom'

type LinkProps = {
    title: string;
    to: string;
    color?: string;
    onClick?: () => void;
    className?: string;
    isOutlined?: boolean;
    underline?: boolean;
}

export const ButtonLink = ({ title, to, color, onClick, className, isOutlined, underline }: LinkProps) => {
    return (
        <ReactLink onClick={onClick} to={to} className={`a-btn ${color} ${className} ${isOutlined && 'a-btn_outlined'} ${underline && 'a-btn_underline'}`}>
            <span>{title}</span>
        </ReactLink>
    )
}

