import React from 'react';

import styles from './ChangeEmailModal.module.scss';
import ModalContainer from 'components/ModalContainer';
import EditableInput from '../EditableInput';

const ChangeEmailModal = () => {
  return (
    <ModalContainer
      title="Change Your Email"
      bodyClassName={styles['change-email-modal']}
      hasCancelButton
      onSubmit={() => {}}
    >
      <form>
        <EditableInput
          title="Enter Your Password"
          placeholder="Enter Password"
        />
        <EditableInput title="New Email" placeholder="Enter New Email" />
        <EditableInput
          title="Confirm New Email"
          placeholder="Confirm New Email"
        />
      </form>
    </ModalContainer>
  );
};

export default ChangeEmailModal;

