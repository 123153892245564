import React from 'react';

import ModalContainer from 'components/ModalContainer';
import CardDetailsForm from '../CardDetailsForm';
import styles from './EditPaymentMethodModal.module.scss';

const EditPaymentMethodModal = () => {
  return (
    <ModalContainer
      title="Edit Payment Method"
      bodyClassName={styles['edit-payment-method-modal']}
      hasCancelButton
      onSubmit={() => {}}
    >
      <CardDetailsForm isTitleVisible />
    </ModalContainer>
  );
};

export default EditPaymentMethodModal;

