import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Layout } from 'components/UI/Layout';
import Home from 'pages/index';
import '../src/styles/index.scss';

import { StepsLayout } from 'components/UI/StepsLayout';
import AccountLayout from 'components/AccountLayout';
import EditorPage from 'pages/EditorPage';
import Step1 from 'pages/steps/step1';
import Step2 from 'pages/steps/step2';
import Step3 from 'pages/steps/step3';
import Step4 from 'pages/steps/step4';
import Step5 from 'pages/steps/step5';
import Step6 from 'pages/steps/step6';
import Step7 from 'pages/steps/step7';
import Step8 from 'pages/steps/step8';
import Step9 from 'pages/steps/step9';
import Step10 from 'pages/steps/step10';
import Step11 from 'pages/steps/step11';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from 'msalConfig';
import DashboardPage from 'pages/DashboardPage';
import CreateNewPasswordPage from 'pages/CreateNewPasswordPage';
import PricingPage from 'pages/PricingPage';
import CheckoutPage from 'pages/CheckoutPage';
import LoginPage from 'pages/LoginPage';
import SignUpPage from 'pages/SignUpPage';
import { useAuth } from 'store/auth';
import RequireAuth from 'components/RequireAuth';
const pca = new PublicClientApplication(msalConfig);

export default function App() {
  const { checkAuth } = useAuth();

  useEffect(() => {
    checkAuth();
  }, [])

  return (
    <BrowserRouter>
      <MsalProvider instance={pca}>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
          </Route>
          <Route
            path="/login"
            index
            element={<LoginPage />}
          />
          <Route
            path="/sign-up"
            index
            element={<SignUpPage />}
          />
          <Route
            path="/create-new-password"
            index
            element={<CreateNewPasswordPage />}
          />
          <Route path="/step1" element={<StepsLayout />}>
            <Route index element={<Step1 />} />
          </Route>
          <Route path="/step2" element={<StepsLayout />}>
            <Route index element={<Step2 />} />
          </Route>
          <Route path="/step3" element={<StepsLayout />}>
            <Route index element={<Step3 />} />
          </Route>
          <Route path="/step4" element={<StepsLayout />}>
            <Route index element={<Step4 />} />
          </Route>
          <Route path="/step5" element={<StepsLayout />}>
            <Route index element={<Step5 />} />
          </Route>
          <Route path="/step6" element={<StepsLayout />}>
            <Route index element={<Step6 />} />
          </Route>
          <Route path="/step7" element={<StepsLayout />}>
            <Route index element={<Step7 />} />
          </Route>
          <Route path="/step8" element={<StepsLayout />}>
            <Route index element={<Step8 />} />
          </Route>
          <Route path="/step9" element={<StepsLayout />}>
            <Route index element={<Step9 />} />
          </Route>
          <Route path="/step10" element={<StepsLayout />}>
            <Route index element={<Step10 />} />
          </Route>
          <Route path="/step11" element={<StepsLayout />}>
            <Route index element={<Step11 />} />
          </Route>

          <Route path="/editor" element={<AccountLayout isEditor />}>
            <Route index element={<EditorPage />} />
          </Route>

          <Route path="/dashboard" element={<AccountLayout />}>
            <Route index element={<RequireAuth Component={DashboardPage} />} />
          </Route>

          <Route path="/pricing" element={<AccountLayout />}>
            <Route index element={<PricingPage />} />
          </Route>

          <Route path="/checkout" element={<AccountLayout />}>
            <Route index element={<CheckoutPage />} />
          </Route>
        </Routes>
      </MsalProvider>
    </BrowserRouter>
  );
}

