import { ButtonLink } from 'components/Button/ButtonLink';
import { ProgressBar } from 'components/ProgressBar/ProgressBar';
import { ServiceSuggestion } from 'components/ServiceSuggestion/ServiceSuggestion';
import { suggestions } from 'features';
import React from 'react';
import { useFourthStepStore } from 'store/steps';

const mockedData = [
  {
    label: 'Teeth Checkup',
    suggestions: [
      'Removing plaque',
      'Cleaning of stones',
      'Tips for dental care',
      'Treatment of canals',
    ],
  },
  {
    label: 'Teeth Whitening',
    suggestions: [
      'Removing plaque',
      'Cleaning of stones',
      'Tips for dental care',
      'Treatment of canals',
    ],
  },
  {
    label: 'Teeth Implants',
    suggestions: [
      'Removing plaque',
      'Cleaning of stones',
      'Tips for dental care',
      'Treatment of canals',
    ],
  },
  {
    label: 'Teeth Braces',
    suggestions: [
      'Removing plaque',
      'Cleaning of stones',
      'Tips for dental care',
      'Treatment of canals',
    ],
  },
];

const Step5 = () => {
  const storeService = useFourthStepStore().getServices();
  console.log(storeService);

  return (
    <>
      <div className="steps__container">
        <h2 className="steps__heading">
          Check a description of each service you offer
        </h2>
        <h3 className="steps__label">
          The AI generates services description based on their names. You can
          always edit the text later.
        </h3>
        <div className="steps__suggestions">
          {storeService[0].name !== ''
            ? storeService.map(({ name, description }, index) => (
                <ServiceSuggestion
                  key={index}
                  number={index + 1}
                  name={name}
                  suggestions={description[0] !== '' ? description : suggestions}
                />
              ))
            : mockedData.map(({ label, suggestions }, index) => (
                <ServiceSuggestion
                  key={index}
                  number={index + 1}
                  name={label}
                  suggestions={suggestions}
                />
              ))}
        </div>
      </div>
      <ProgressBar step={5} />
      <div className="steps__multi-container">
        <ButtonLink underline isOutlined to="/step4" title="Back" />
        <ButtonLink isOutlined to="/step6" title="Next" />
      </div>
    </>
  );
};

export default Step5;
