import { ButtonLink } from 'components/Button/ButtonLink'
import { ProgressBar } from 'components/ProgressBar/ProgressBar'
import { Input } from 'components/UI/Input'
import React, { useEffect, useState } from 'react'
import { useNinethStepStore } from 'store/steps'

const Step9 = () => {
    const contactsState = useNinethStepStore().getContact
    const setContactsState = useNinethStepStore().setNinethStep
    const [contacts, setContacts] = useState(contactsState)

    const handleChange = ({e, value}: {e: any, value: string}) => {
        setContacts((prev) => ({...prev, [value]: e.target.value.toString()}))
    }

    useEffect(() => {
        setContactsState(contacts)
    }, [contacts])

    return (
        <>
            <div className='steps__container'>
                <h2 className='steps__heading'>What are your clinic's phone number, email address, and any other relevant contact details?</h2>
                <div className='steps__details-container'>
                    <div>
                        <h3>Phone Number</h3>
                        <Input type='tel' placeholder='Enter Your Phone' onChange={(e) => handleChange({e, value: 'number'})} value={contacts.number}/>
                    </div>
                    <div>
                        <h3>Email</h3>
                        <Input type='email' placeholder='Enter Your Email' onChange={(e) => handleChange({e, value: 'email'})} value={contacts.email}/>
                    </div>
                </div>
            </div>
            <ProgressBar step={9} />
            <div className='steps__multi-container'>
                <ButtonLink underline isOutlined to='/step8' title='Back' />
                <ButtonLink isOutlined to="/step10" title='Next' />
            </div>
        </>
    )
}

export default Step9