import React from 'react';
import './button.scss';

interface ButtonProps {
  underline?: boolean;
  title: string;
  color?: string;
  className?: string;
  onClick?: () => void;
  isOutlined?: boolean;
  clear?: boolean;
  type?: 'button' | 'submit' | 'reset';
}

export const Button = ({
  title,
  color,
  className,
  onClick,
  underline,
  isOutlined,
  clear,
  type = 'button',
}: ButtonProps) => {
  return (
    <>
      {color ? (
        <button
          type={type}
          onClick={onClick}
          className={`a-btn ${color} ${className} ${isOutlined && 'a-btn_outlined'
            } ${underline && 'a-btn_underline'} ${clear && 'a-btn_clear'}`}
        >
          <span>{title}</span>
        </button>
      ) : (
        <button
          type={type}
          onClick={onClick}
          className={`a-btn ${className} ${isOutlined && 'a-btn_outlined'} ${underline && 'a-btn_underline'
            } ${clear && 'a-btn_clear'}`}
        >
          <span>{title}</span>
        </button>
      )}
    </>
  );
};

