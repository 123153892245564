import { content, servicesExample, suggestions, teamExample } from 'features';
import React, { useEffect, useState } from 'react';
import { AiOutlineClockCircle, AiOutlineMail } from 'react-icons/ai';
import { MdOutlinePhone, MdOutlineLocationOn } from 'react-icons/md';
import { HiMiniArrowSmallUp } from 'react-icons/hi2';
import {
  BiLogoFacebook,
  BiLogoInstagram,
  BiLogoLinkedin,
} from 'react-icons/bi';
import Links from './components/Links';
import {
  useEighthStepStore,
  useFirstStepStore,
  useFourthStepStore,
  useNinethStepStore,
  useSecondStepStore,
  useSeventhStepStore,
  useSixthStepStore,
  useTenthStepStore,
  useThirdStepStore,
} from 'store/steps';
import Service from './components/Service';
import Team from './components/Team';
import Header from './components/Header';
import { COLOR_MAP, TColors } from './const';

const Template = () => {
  const [active, setActive] = useState('');
  const [color, setColor] = useState<TColors>('color1');
  const [visible, setVisible] = useState(false);
  const storeSlogan = useFirstStepStore((state) => state.getSlogan());
  const storeColor = useSecondStepStore((state) => state.getColors());
  const storePhotos = useThirdStepStore((state) => state.getPhotos());
  const storeServices = useFourthStepStore((state) => state.getServices());
  const storeTeam = useSixthStepStore((state) => state.getTeam());
  const storeSchedule = useSeventhStepStore((state) => state.getSchedule());
  const storeAddress = useEighthStepStore((state) => state.getAddress());
  const storeContacts = useNinethStepStore((state) => state.getContact());
  const storeSocial = useTenthStepStore((state) => state.getSocial());

  console.log(storePhotos);
  console.log(color);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  window.addEventListener('scroll', toggleVisible);

  const handleClick = (title: string) => {
    setActive(title);

    if (active === title) {
      setActive('');
    }
  };

  useEffect(() => {
    setColor((storeColor as any).name);
  }, [storeColor]);

  return (
    <div className="steps__template">
      <Header color={color} />
      <section id="main" className="steps__template__section">
        <div className="steps__template__section__main">
          <div className="steps__template__section__main__text">
            <h3>Premium Dental Care For Every Client</h3>
            <p>
              {storeSlogan ? (
                <>{storeSlogan}</>
              ) : (
                <>
                  Smile confidently with our expert dental care services. Your
                  one-stop solution for a healthy, beautiful smile!
                </>
              )}
            </p>
            <div className="steps__template__section__main__text__time">
              <button
                className="a-btn blue"
                style={{
                  backgroundColor: COLOR_MAP[color],
                }}
              >
                Make an Appointment
              </button>
              <div className="steps__template__section__main__text__time__details">
                <p>Mon-Fri: 9:00 AM - 7:00 PM</p>
                <p>Sat: 10:00 AM - 4:00 PM</p>
              </div>
            </div>
          </div>
          <div className="steps__template__section__main__img">
            <img src="/assets/example.png" alt="" />
          </div>
        </div>
      </section>
      <section id="about" className="steps__template__section">
        <div className="steps__template__section__about">
          <div className="steps__template__section__about__img">
            {storePhotos.first !== '' ? (
              <>
                <img src={storePhotos.first} alt="" />
                <img src={storePhotos.second} alt="" />
              </>
            ) : (
              <>
                <img src="/assets/example.png" alt="" />
                <img src="/assets/example.png" alt="" />
              </>
            )}
          </div>
          <div className="steps__template__section__about__text">
            <h3>
              <span className={color}>About </span>
              <span>Us</span>
            </h3>
            <p>
              Welcome to our state-of-the-art dental clinic, where we are
              committed to providing exceptional dental care to our patients.
              Our mission is to help you achieve and maintain optimal oral
              health in a comfortable and relaxing environment.
              <br />
              <br />
              At our clinic, we understand that every patient has unique dental
              needs, and we strive to deliver personalized care to each
              individual.
              <br />
              <br />
              Our clinic is equipped with advanced equipment and modern
              amenities to ensure your comfort during your visit. We also offer
              flexible appointment scheduling to accommodate your busy schedule.
            </p>
          </div>
        </div>
      </section>
      <section id="service" className="steps__template__section">
        <div className="steps__template__section__service">
          <h3>
            <span>Our </span>
            <span className={color}>Services</span>
          </h3>
          <div className="steps__template__section__service__details">
            {storeServices[0].name !== '' ? (
              <>
                <div className="steps__template__section__service__details__img">
                  {storeServices.map((service) => (
                    <Service
                      name={service?.name}
                      desc={service.description}
                      color={color}
                    />
                  ))}
                </div>
              </>
            ) : (
              <>
                <div className="steps__template__section__service__details__img">
                  {servicesExample.map((service) => (
                    <Service
                      name={service?.name}
                      desc={suggestions}
                      color={color}
                    />
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </section>
      <section id="team" className="steps__template__section">
        <div className="steps__template__section__team">
          <h3>
            <span>Meet Our </span>
            <span className={color}>Team</span>
          </h3>
          <div className="steps__template__section__team__wrapper">
            {storeTeam[0]?.name !== ''
              ? storeTeam.map((team) => (
                  <Team
                    name={team?.name}
                    title={team.title}
                    photo={team.photo}
                    color={color}
                  />
                ))
              : teamExample.map((team) => (
                  <Team
                    name={team?.name}
                    title={team.title}
                    photo={team.photo}
                    color={color}
                  />
                ))}
          </div>
        </div>
      </section>
      <section id="questions" className="steps__template__section">
        <div className="steps__template__section__questions">
          <h3>
            <span>Frequently Asked </span>
            <span className={color}>Questions</span>
          </h3>
          <div className="m-faq-list steps__template__section__questions__list">
            {content.map(({ title, body }) => (
              <div key={title} className="m-faq-item">
                <div
                  className={`title ${active === title && 'active'}`}
                  onClick={() => handleClick(title)}
                >
                  <span>{title}</span>
                </div>
                {active === title && <div className="desc">{body}</div>}
              </div>
            ))}
          </div>
        </div>
      </section>
      <section id="form" className="steps__template__section">
        <div className="steps__template__section__form">
          <div className="steps__template__section__form__img">
            <img src="/assets/example.png" alt="" />
          </div>
          <div className="steps__template__section__form__inputs">
            <h3>
              <span>Become The Next Our </span>
              <span className={color}>Happy</span>
              <span> Client</span>
            </h3>
            <p>
              Book your appointment today and take the first step towards a
              healthier, happier smile!
            </p>
            <form action="">
              <div>
                <input type="text" placeholder="First Name" />
                <input type="text" placeholder="Last Name" />
                <input type="email" placeholder="Email" />
                <input type="text" placeholder="Phone" />
              </div>
              <textarea placeholder="Describe Your Problem (Optional)" />
              <button
                type="submit"
                style={{
                  backgroundColor: COLOR_MAP[color],
                }}
              >
                Send Request
              </button>
            </form>
          </div>
        </div>
      </section>
      <div
        className="back-to-top"
        style={{
          backgroundColor: COLOR_MAP[color],
          display: visible ? 'inline' : 'none',
        }}
        onClick={scrollToTop}
      >
        <HiMiniArrowSmallUp />
      </div>
      <footer>
        <div className="steps__template__footer">
          <div className="steps__template__footer__main">
            <div className="steps__template__footer__main__logo">
              <h3>
                <span className={color}>Art</span>
                <span>Smile</span>
              </h3>
              <div className="steps__template__footer__main__logo__social">
                <a href={storeSocial.facebook}>
                  <BiLogoFacebook />
                </a>
                <a href={storeSocial.instagram}>
                  <BiLogoInstagram />
                </a>
                <a href={storeSocial.linkedIn}>
                  <BiLogoLinkedin />
                </a>
              </div>
            </div>
            <div className="steps__template__footer__main__nav">
              <Links />
            </div>
            <div className="steps__template__footer__main__worktime">
              <AiOutlineClockCircle />
              <div className="steps__template__footer__main__worktime__details">
                <p>
                  {' '}
                  Mon-Fri:{' '}
                  {storeSchedule.weekdays.fromTime !== ''
                    ? storeSchedule.weekdays.fromTime
                    : '9:00'}{' '}
                  {storeSchedule.weekdays.fromValue !== ''
                    ? storeSchedule.weekdays.fromValue
                    : 'AM'}{' '}
                  -{' '}
                  {storeSchedule.weekdays.toTime !== ''
                    ? storeSchedule.weekdays.toTime
                    : '7:00'}
                  {storeSchedule.weekdays.toValue !== ''
                    ? storeSchedule.weekdays.toValue
                    : 'AM'}
                </p>
                <p>
                  Sat:{' '}
                  {storeSchedule.weekends.fromTime !== ''
                    ? storeSchedule.weekends.fromTime
                    : '9:00'}{' '}
                  {storeSchedule.weekends.fromValue !== ''
                    ? storeSchedule.weekends.fromValue
                    : 'AM'}{' '}
                  -{' '}
                  {storeSchedule.weekends.toTime !== ''
                    ? storeSchedule.weekends.toTime
                    : '7:00'}{' '}
                  {storeSchedule.weekends.toValue !== ''
                    ? storeSchedule.weekends.toValue
                    : 'PM'}
                </p>
                <p>Sun: Closed</p>
              </div>
            </div>
            <div className="steps__template__footer__main__contacts">
              <div className="steps__template__footer__main__contacts__details">
                <MdOutlinePhone />{' '}
                <p>
                  {storeContacts.number !== ''
                    ? storeContacts.number
                    : '1-800-123-4567'}
                </p>
              </div>
              <div className="steps__template__footer__main__contacts__details">
                <AiOutlineMail />{' '}
                <p>
                  {storeContacts.email !== ''
                    ? storeContacts.email
                    : 'dentalplus-medical@gmail.com'}
                </p>
              </div>
              <div className="steps__template__footer__main__contacts__details">
                <MdOutlineLocationOn />{' '}
                <p>
                  Brooklyn,{' '}
                  {storeAddress.city !== '' ? storeAddress.city : 'NY'} 10036,{' '}
                  {storeAddress.country !== '' ? storeAddress.country : 'US'}
                </p>
              </div>
            </div>
            <div className="steps__template__footer__main__map">
              <img src="/assets/example.png" alt="" />
            </div>
          </div>
          <div className="steps__template__footer__copyrights">
            <p>Made by Idesn</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Template;
