import React, { forwardRef } from 'react';
import '../../styles/components/input.scss';
import { useFormContext } from 'react-hook-form';
import classNames from 'classnames';

interface InputProps {
  id?: string;
  type?: string;
  onChange?: (e: any) => void;
  className?: string;
  placeholder?: string;
  value?: any;
  isTextArea?: boolean;
  required?: boolean;
  name?: string;
  disabled?: boolean;
  isHookForm?: boolean;
  error?: string;
}

export const Input = forwardRef(
  ({
    type,
    onChange,
    className,
    placeholder,
    value,
    isTextArea,
    required,
    id,
    name,
    disabled,
    isHookForm,
    error,
  }: InputProps, ref: any) => {
    const { register } = useFormContext() || {};

    return (
      <div className="input">
        {isTextArea ? (
          <textarea
            required={required}
            defaultValue={value}
            placeholder={placeholder}
            className={className}
            onChange={onChange}
            disabled={disabled}
            ref={ref}
          />
        ) : (
          <input
            required={required}
            defaultValue={value}
            value={value}
            placeholder={placeholder}
            className={classNames(className, { error: !!error })}
            type={type}
            id={id}
            disabled={disabled}
            {...(isHookForm && name ? register(name) : { ref, onChange, name })}
          />
        )}
      </div>
    );
  }
);

