import React, { FC } from 'react';

import EditableInput from 'components/Dashboard/components/EditableInput';
import styles from './CreateNewPasswordPage.module.scss';
import { Button } from 'components/Button/Button';
import MinimalHeader from 'components/MinimalHeader';

const CreateNewPasswordPage: FC = () => {
  return (
    <div className={styles['create-new-password-page']}>
      <MinimalHeader />
      <main>
        <h1>Create New Password</h1>
        <form>
          <EditableInput
            title="New Password"
            placeholder="Enter Your New Password"
          />
          <EditableInput
            title="Confirm New Password"
            placeholder="Confirm Your New Password"
          />
          <Button title="Save" />
        </form>
      </main>
    </div>
  );
};

export default CreateNewPasswordPage;

