import React, { FC, useState } from 'react';

import styles from './ChangePasswordModal.module.scss';
import ModalContainer from 'components/ModalContainer';
import EditableInput from '../EditableInput';
import { useModal } from 'store/modal';

interface IChangePasswordModalProps {
  initialResetPassword?: boolean;
}

const ChangePasswordModal: FC<IChangePasswordModalProps> = ({ initialResetPassword = false }) => {
  const [isPasswordReset, setIsPasswordReset] = useState(false);

  const modal = useModal();

  if (initialResetPassword || isPasswordReset) {
    return (
      <ModalContainer
        title="Check Your Email"
        bodyClassName={styles['change-password-modal']}
        submitButtonTitle='OK'
        onSubmit={modal.close}
      >
        <p>Reset password instructions were sent to your email.</p>
      </ModalContainer>
    );
  }

  return (
    <ModalContainer
      title="Change Your Password"
      bodyClassName={styles['change-password-modal']}
      hasCancelButton
      onSubmit={() => { }}
    >
      <form>
        <div className={styles.current_password}>
          <EditableInput
            title="Current Password"
            placeholder="Enter Current Password"
          />
          <p>
            Forgot your password?{' '}
            <button onClick={() => setIsPasswordReset(true)}>Reset</button>
          </p>
        </div>
        <EditableInput title="New Password" placeholder="Enter New Password" />
        <EditableInput
          title="Confirm New Password"
          placeholder="Confirm New Password"
        />
      </form>
    </ModalContainer>
  );
};

export default ChangePasswordModal;

