import React from 'react'
import { Faq } from 'components/Faq/Faq';
import { Features } from 'components/Features/Features';
import { Hero } from 'components/Hero/Hero';
import { HowItWorks } from 'components/HowItWorks/HowItWorks';
import { Ready } from 'components/Ready/Ready';
import { Testimonials } from 'components/Testimonials/Testimonials';

const HomePage = () => {
    return (
        <>
            <Hero />
            <HowItWorks />
            <Features />
            <Testimonials />
            <Faq />
            <Ready />
        </>
    )
}

export default HomePage;