import { Input } from 'components/UI/Input';
import React, { ChangeEvent } from 'react';
import './staff-suggestion.scss';
import { StaffMember } from 'types/steps';

interface StuffSuggestionProps {
  setStaff: React.Dispatch<
    React.SetStateAction<
      {
        name: string;
        title: string;
        photo: string;
      }[]
    >
  >;
  number: number;
  name: string;
  title: string;
  photo: string;
  staff: StaffMember[];
}

export const StaffSuggestion = ({
  number,
  staff,
  setStaff,
}: StuffSuggestionProps) => {

  const suggestionObject = staff[number - 1];

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setStaff((prevStaff) => {
      const updatedStaff = [...prevStaff];
      updatedStaff[number - 1] = {
        ...updatedStaff[number - 1],
        [name]: value,
      };
      return updatedStaff;
    });
  };

  const handlePhotoChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setStaff((prevStaff) => {
          const updatedStaff = [...prevStaff];
          updatedStaff[number - 1] = {
            ...updatedStaff[number - 1],
            photo: e?.target?.result as string,
          };
          return updatedStaff;
        });
      };
      reader.readAsDataURL(files[0]);
    }
  };

  const handleRemoveImage = () => {
    setStaff((prevStaff) => {
      const updatedStaff = [...prevStaff];
      updatedStaff[number - 1] = {
        ...updatedStaff[number - 1],
        photo: '',
      };
      return updatedStaff;
    });
  };

  return (
    <div className="staff-suggestion">
      <div className="staff-suggestion__topics">
        <h3>Team Member {number}</h3>
      </div>
      <div className="staff-suggestion__stuff">
        <div className="staff-suggestion__inputs">
          <Input
            name="name"
            onChange={(e) => handleChange(e)}
            placeholder="Enter Member’s Full Name"
            value={suggestionObject.name}
          />
          <Input
            name="title"
            onChange={(e) => handleChange(e)}
            placeholder="Enter Member’s Title"
            value={suggestionObject.title}
          />
        </div>
        <div className="staff-suggestion__photo-container">
          <input
            onChange={handlePhotoChange}
            accept="image/*"
            className="staff-suggestion__photo"
            type="file"
          />
          <span className="staff-suggestion__photo-title">Add photo</span>
          {(suggestionObject.photo as string) && (
            <div className="staff-suggestion__uploaded">
              <img
                className="staff-suggestion__uploaded-preview"
                alt="cock"
                src={suggestionObject.photo}
              />
              <span
                onClick={handleRemoveImage}
                className="staff-suggestion__uploaded-delete"
              >
                X
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

