import { Button } from 'components/Button/Button';
import React from 'react'
import './service-suggestion.scss'

export const ServiceSuggestion = ({ number, name, suggestions }: { number: number; name: string; suggestions: string[] }) => {
    return (
        <div className='service-suggestion'>
            <div className='service-suggestion__topics'>
                <h3>Service {number}: {name}</h3>
                <Button isOutlined clear title='Regenerate new' />
            </div>
            <div className='service-suggestion__description-container'>
                {suggestions.map((text) => <span className='service-suggestion__description' key={text}>{text}</span>)}
            </div>
        </div>
    )
}
