import React from 'react';
import { Link } from "react-router-dom";

const Logo = () => {
  return (
    <Link to="/">
      <img alt="idesn" src="/assets/idesn_logo.svg" />
    </Link>
  );
};

export default Logo;
