import React, { FC } from 'react';
import cn from 'classnames';

import { CARD_ICONS } from 'components/Dashboard/const/cardIcons';
import styles from './PaymentMethod.module.scss';
import { useModal } from 'store/modal';
import AddPaymentMethodModal from '../AddPaymentMethodModal';
import EditPaymentMethodModal from '../EditPaymentMethodModal';

interface IPaymentMethodProps {
  isPlaceholder?: boolean;
  lastFourDigits?: string;
  expirationDate?: string;
  cardType?: 'mastercard' | 'visa';
  onRemove?: () => void;
  isActive?: boolean;
}

const PaymentMethod: FC<IPaymentMethodProps> = ({
  isPlaceholder = false,
  lastFourDigits,
  expirationDate,
  cardType,
  onRemove,
  isActive,
}) => {
  const modal = useModal();

  if (isPlaceholder) {
    return (
      <div
        onClick={() => modal.open(AddPaymentMethodModal)}
        className={cn(
          styles['payment-method'],
          styles['payment-method--placeholder']
        )}
      >
        <p>+ Add new payment method</p>
      </div>
    );
  }

  return (
    <div
      className={cn(styles['payment-method'], {
        [styles.active]: isActive,
      })}
    >
      <img src={CARD_ICONS[cardType!]} alt="Card logo" />
      <div className={styles['payment-method__content']}>
        <span className={styles['payment-method__content__number']}>
          ••••{lastFourDigits}
        </span>
        <span className={styles['payment-method__content__expires']}>
          Expires {expirationDate}
        </span>
        <div className={styles['payment-method__content__actions']}>
          <button onClick={() => modal.open(EditPaymentMethodModal)}>
            Edit
          </button>
          <hr />
          <button onClick={onRemove}>Remove</button>
        </div>
      </div>
    </div>
  );
};

export default PaymentMethod;

