import React, { useState } from 'react';

import mastercardLogo from '../../assets/mastercard-logo.webp';
import visaLogo from '../../assets/visa-logo.webp';
import discoverLogo from '../../assets/discover-logo.webp';
import americanExpressLogo from '../../assets/american-express-logo.webp';
import paypalIcon from '../../assets/icons/paypal.svg';

import CardDetailsForm from 'components/Dashboard/components/CardDetailsForm';
import { Button } from 'components/Button/Button';
import styles from './CheckoutPage.module.scss';
import cn from 'classnames';
import { MdCreditCard } from 'react-icons/md';

enum EPaymentMethod {
  CREDIT_CARD = 'Credit Card',
  PAYPAL = 'PayPal',
}

const CheckoutPage = () => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<EPaymentMethod>(EPaymentMethod.CREDIT_CARD);

  let content = null;

  if (selectedPaymentMethod === EPaymentMethod.CREDIT_CARD) {
    content = (
      <div className={styles.form_container}>
        <div className={styles.form_container}>
          <CardDetailsForm />
          <Button title="Submit" />
        </div>

        <div className={styles.accepted_payments}>
          <p>Accepted Payment Methods</p>
          <ul>
            {[mastercardLogo, visaLogo, discoverLogo, americanExpressLogo].map(
              (logo, index) => (
                <li key={index}>
                  <img src={logo} />
                </li>
              )
            )}
          </ul>
        </div>
      </div>
    );
  } else {
    content = (
      <div className={styles.paypal_container}>
        <p>You will be taken to PayPal to continue payment process.</p>
        <Button title="Continue" />
      </div>
    );
  }

  return (
    <div className={styles.checkout_page}>
      <h1>Complete Your Purchase</h1>
      <h2>
        <span>7 Days</span> Money Back Guarantee
      </h2>

      <div className={styles.payment_methods_container}>
        <h3>Payment Method</h3>
        <div className={styles.payment_methods}>
          <button
            onClick={() => setSelectedPaymentMethod(EPaymentMethod.CREDIT_CARD)}
            className={cn(styles.payment_methods__item, {
              [styles.active]:
                selectedPaymentMethod === EPaymentMethod.CREDIT_CARD,
            })}
          >
            <MdCreditCard className={styles.credit_card_icon} />
            {EPaymentMethod.CREDIT_CARD}
          </button>
          <button
            onClick={() => setSelectedPaymentMethod(EPaymentMethod.PAYPAL)}
            className={cn(styles.payment_methods__item, {
              [styles.active]: selectedPaymentMethod === EPaymentMethod.PAYPAL,
            })}
          >
            <img src={paypalIcon} />
            {EPaymentMethod.PAYPAL}
          </button>
        </div>
      </div>
      {content}
    </div>
  );
};

export default CheckoutPage;

