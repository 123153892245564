import { FC } from 'react';
import { create } from 'zustand';

interface ModalState {
  component: FC<any> | null;
  props: any;
  open: (component: FC<any>, props?: any) => void;
  close: () => void;
}

export const useModal = create<ModalState>((set) => ({
  component: null,
  props: null,
  open: (component, props) => set({ component, props }),
  close: () => set({ component: null, props: null }),
}));

