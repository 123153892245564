import React, { useState } from 'react';

import SubscriptionCard from 'components/Subscription/components/SubscriptionCard';
import Switch from 'components/UI/Switch';
import {
  annualSubscriptionPlans,
  monthToMonthSubscriptionPlans,
} from 'components/Subscription/const/subscriptionPlans';
import styles from './PricingPage.module.scss';

const PricingPage = () => {
  const [selectedSwitchIndex, setSelectedSwitchIndex] = useState(0);

  const handleSwitchChange = (index: number) => {
    setSelectedSwitchIndex(index);
  };

  const selectedSubscriptionPlans =
    selectedSwitchIndex === 0
      ? annualSubscriptionPlans
      : monthToMonthSubscriptionPlans;

  return (
    <div className={styles.pricing_page}>
      <h1>Subscription Plans</h1>
      <Switch
        selectedIndex={selectedSwitchIndex}
        onChange={handleSwitchChange}
        left={
          <>
            Annual <span>(Save 25%)</span>
          </>
        }
        right="Month-to-month"
      />
      <div className={styles.cards}>
        {selectedSubscriptionPlans.map((card, index) => (
          <SubscriptionCard key={index} card={card} />
        ))}
      </div>
    </div>
  );
};

export default PricingPage;

