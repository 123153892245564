import { ButtonLink } from 'components/Button/ButtonLink';
import { ColorPicker } from 'components/ColorPicker/ColorPicker';
import { ProgressBar } from 'components/ProgressBar/ProgressBar';
import React, { useEffect, useState } from 'react'
import { useSecondStepStore } from 'store/steps';

interface Colors {
    main: string;
    primary: string;
    name: string
}

const Step2 = () => {
    const [pickedColor, setPickedColor] = useState<Colors>({ main: '', primary: '', name: '' });
    const setColors = useSecondStepStore().setSecondStep;

    useEffect(() => {
        setColors(pickedColor)
    }, [pickedColor])

    return (
        <>
            <div className='steps__container'>
                <h2 className='steps__heading'>Which colors you like best?</h2>
                <h3 className='steps__label'>Choose the color combination for your website.</h3>
                <ColorPicker setColor={setPickedColor} />
            </div>
            <ProgressBar step={2} />
            <div className='steps__container'>
                <ButtonLink isOutlined to="/step3" className='steps__button' title='Next' />
            </div>
        </>
    )
}

export default Step2