import React from 'react';
import { MdClose, MdRefresh } from 'react-icons/md';

import { Button } from 'components/Button/Button';
import { useEditor } from 'store/editor';
import styles from './Sidebar.module.scss';

const Sidebar = () => {
  const { toggleEditing } = useEditor((state) => state);

  return (
    <div className={styles.sidebar}>
      <div className={styles.sidebar__header}>
        <h3>Edit</h3>
        <button onClick={toggleEditing}>
          <MdClose />
        </button>
      </div>

      <hr />

      <div className={styles.field}>
        <div className={styles.field__header}>
          <h3>Title</h3>
          <button>
            <MdRefresh />
          </button>
        </div>

        <div className={styles.field__content}>
          <textarea placeholder="Enter your title here" />
        </div>
      </div>

      <hr />

      <div className={styles.field}>
        <div className={styles.field__header}>
          <h3>Picture</h3>
          <button>
            <MdRefresh />
          </button>
        </div>

        <div className={styles.field__content}>
          <img alt="" src="/assets/testimonials/first.png" />
          <Button clear underline title="Upload your picture" />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

