import React from 'react'
import { ButtonLink } from 'components/Button/ButtonLink'

export const Ready = () => {
    return (
        <section className="o-section o-ready">
            <div className="container">
                <div
                    className="section-content"
                    style={{
                        maxWidth: '920px',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <h2>
                        <em>Ready</em> to Build Your Dental Clinic&#39;s
                        Professional Website in Just 5 Minutes?
                    </h2>
                    <p style={{ marginBottom: '40px' }}>
                        Click the button below to start your AI-powered
                        website creation journey and attract new patients
                        today!
                    </p>
                    <ButtonLink
                        to="/step1"
                        title="Get Started For Free"
                    />
                </div>
            </div>
        </section>
    )
}

