import React, { FC, useEffect, useRef, useState } from 'react';

import { Input } from 'components/UI/Input';
import styles from './EditableInput.module.scss';
import useClickOutside from 'hooks/useClickOutside';

interface IEditableInputProps {
  title: string;
  value?: string;
  onClick?: () => void;
  hasEdit?: boolean;
  isConfirmed?: boolean;
  placeholder?: string;
}

const EditableInput: FC<IEditableInputProps> = ({
  title,
  value,
  onClick,
  hasEdit,
  isConfirmed,
  placeholder,
}) => {
  const [isDisabled, setIsDisabled] = useState(true);

  const inputRef = useRef<HTMLInputElement>(null);
  useClickOutside(inputRef, () => setIsDisabled(true));

  const handleEdit = () => setIsDisabled(!isDisabled);

  useEffect(() => {
    if (!isDisabled) {
      inputRef.current?.focus();
    }
  }, [isDisabled]);

  return (
    <div className={styles['editable-input']}>
      <div className={styles['editable-input__header']}>
        <h4>
          {title} {isConfirmed && <span>Confirmed</span>}
        </h4>
        {hasEdit && <button onClick={onClick || handleEdit}>Edit</button>}
      </div>
      <Input
        className={styles.input}
        value={value}
        disabled={hasEdit ? isDisabled : false}
        ref={inputRef}
        placeholder={placeholder}
      />
    </div>
  );
};

export default EditableInput;

