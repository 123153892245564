import React, { FC, PropsWithChildren } from 'react';

import closeIcon from '../../assets/icons/close.svg';
import { useModal } from 'store/modal';
import styles from './ModalContainer.module.scss';
import { Button } from 'components/Button/Button';

type TModalContainerProps = PropsWithChildren<{
  title: string;
  bodyClassName?: string;
  submitButtonTitle?: string;
  hasCancelButton?: boolean;
  onSubmit?: () => void;
}>;

const ModalContainer: FC<TModalContainerProps> = ({
  title,
  bodyClassName,
  children,
  submitButtonTitle = 'Save',
  hasCancelButton,
  onSubmit,
}) => {
  const { close } = useModal();

  return (
    <div className={styles['modal-container']} onClick={close}>
      <div
        className={styles['modal-container__content']}
        onClick={(event) => event.stopPropagation()}
      >
        <header>
          <h3>{title}</h3>
          <button onClick={close}>
            <img src={closeIcon} />
          </button>
        </header>
        <div className={bodyClassName}>{children}</div>
        <div className={styles.buttons}>
          {hasCancelButton && (
            <Button title="Cancel" isOutlined onClick={close} />
          )}
          {onSubmit && <Button title={submitButtonTitle} onClick={onSubmit} />}
        </div>
      </div>
    </div>
  );
};

export default ModalContainer;

