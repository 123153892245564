import React, { FC } from 'react';

import { Input } from 'components/UI/Input';
import styles from './CardDetailsForm.module.scss';

interface ICardDetailsFormProps {
  isTitleVisible?: boolean;
}

const CardDetailsForm: FC<ICardDetailsFormProps> = ({ isTitleVisible }) => {
  return (
    <div className={styles['card-details-form']}>
      {isTitleVisible && <h4>Card Details</h4>}
      <form>
        <Input placeholder="Card Number" />
        <Input placeholder="Expiration Date" />
        <Input placeholder="CVV" />
        <Input placeholder="First Name" />
        <Input placeholder="Last Name" />
      </form>
    </div>
  );
};

export default CardDetailsForm;

