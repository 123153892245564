import React from 'react'
import { TryIt } from 'components/TryIt/TryIt'

import './process.scss'

export const HowItWorks = () => {
    return (
        <section id="process" className="o-section o-process">
            <div className="top">
                <div className="container">
                    <h2>
                        How It <em>Works</em>
                    </h2>
                    <div className="o-process-items">
                        <div className="item">
                            <div className="item-inner">
                                <h3 className="title">
                                    Answer a Few Simple Questions
                                </h3>
                                <p className="desc">
                                    Tell our AI about your dental practice,
                                    services offered, your team, and your
                                    clinic&#39;s unique features
                                </p>
                            </div>
                        </div>
                        <div className="item">
                            <div className="item-inner">
                                <h3 className="title">
                                    AI Creates Your Customized Website
                                </h3>
                                <p className="desc">
                                    Our advanced AI technology will generate
                                    a professional, mobile-responsive
                                    website tailored to your dental
                                    practice&#39;s needs in just a few
                                    minutes.
                                </p>
                            </div>
                        </div>
                        <div className="item">
                            <div className="item-inner">
                                <h3 className="title">
                                    Make Easy Edits and Launch
                                </h3>
                                <p className="desc">
                                    Review your new website, make any
                                    necessary edits with our user-friendly
                                    editor, and launch it with a single
                                    click. Boost your online presence and
                                    attract new patients in no time!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bottom">
                <div className="container">
                    <TryIt />
                </div>
            </div>
        </section>
    )
}
