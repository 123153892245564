import { Button } from 'components/Button/Button';
import React, { Dispatch, SetStateAction, useState } from 'react';
import Select from 'react-select';
import './open-hours.scss';
import { DefaultSchedule } from 'types/steps';
const weekDayNames = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
const weekEndNames = ['Saturday', 'Sunday'];
const renderAvailableHours = () => {
  const hours = [];
  for (let i = 0; i < 13; i++) {
    const hour = (i < 10 ? '0' : '') + i + ':00';
    const object = { value: hour, label: hour };
    hours.push(object);
  }
  return hours;
};

const availableHours :{ value: string; label: string; }[] = renderAvailableHours();

const availableTime = [
  { value: 'AM', label: 'AM' },
  { value: 'PM', label: 'PM' },
];
const defaultWeekdayOpenValue = availableHours.find(
  (hour) => hour.label === '09:00'
);

interface OpenHoursProps {
  title: 'Weekdays' | 'Weekends';
  schedule: DefaultSchedule;
  setSchedule: Dispatch<SetStateAction<DefaultSchedule>>;
}

export const OpenHours = ({ title, setSchedule, schedule }: OpenHoursProps) => {
  const [switchDay, setSwitchDay] = useState(false);

  const typeOfDays =
    switchDay && title === 'Weekends' ? weekEndNames : weekDayNames;

  const renderMultipleSelects = (isFirst = false, title = '') => (
    <div style={{ width: '100%' }}>
      {typeOfDays.map((day) => (
        <>
          {isFirst ? (
            <h3 className="open-hours__title">{day}</h3>
          ) : (
            <h3 className="open-hours__title open-hours__title_invis">{day}</h3>
          )}
          <div key={day} className="open-hours__special-wrapper">
            <div className="open-hours__input-special">
              <h3 className="open-hours__label-special">{title}</h3>
              <Select
                key={day}
                components={{
                  IndicatorSeparator: () => null,
                }}
                defaultValue={defaultWeekdayOpenValue}
                className="open-hours__select_special"
                options={availableHours}
              />
            </div>
            <div>
              <h3 className="open-hours__label-special open-hours__label-special_invis">
                Close
              </h3>
              <Select
                components={{
                  IndicatorSeparator: () => null,
                }}
                placeholder="AM"
                className="open-hours__select_special"
                options={availableTime}
              />
            </div>
          </div>
        </>
      ))}
    </div>
  );

  const handleScheduleChange = ({
    e,
    title,
    type,
  }: {
    e: any;
    title: string;
    type: string;
  }) => {
    if (title === 'Weekdays') {
      setSchedule((prev) => ({
        ...prev,
        weekdays: {
          ...prev.weekdays,
          [type === 'open' ? 'fromTime' : 'toTime']: e.value,
        },
      }));
    } else {
      setSchedule((prev) => ({
        ...prev,
        weekends: {
          ...prev.weekends,
          [type === 'open' ? 'fromTime' : 'toTime']: e.value,
        },
      }));
    }
  };

  const handleScheduleValueChange = ({
    e,
    title,
    type,
  }: {
    e: any;
    title: string;
    type: string;
  }) => {
    if (title === 'Weekdays') {
      setSchedule((prev) => ({
        ...prev,
        weekdays: {
          ...prev.weekdays,
          [type === 'open' ? 'fromValue' : 'toValue']: e.value,
        },
      }));
    } else {
      setSchedule((prev) => ({
        ...prev,
        weekends: {
          ...prev.weekends,
          [type === 'open' ? 'fromValue' : 'toValue']: e.value,
        },
      }));
    }
  };

  return (
    <div className="open-hours">
      <div className="open-hours__heading">
        <h2 className="open-hours__title">{title}</h2>
        {!switchDay && (
          <Button
            onClick={() => setSwitchDay(true)}
            className="open-hours__button"
            isOutlined
            clear
            title="Set each day separately"
          />
        )}
      </div>
      <div className="open-hours__select-wrapper">
        <div className="open-hours__select-block">
          {!switchDay && <h3 className="open-hours__label">Open</h3>}
          <div className="open-hours__select-section">
            {!switchDay ? (
              <>
                <Select
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  defaultValue={availableHours.find(
                    (hour) => hour.label === `${title === 'Weekdays' ? schedule.weekdays.fromTime : schedule.weekends.fromTime}`
                  )}
                  className="open-hours__select"
                  options={availableHours}
                  onChange={(e) =>
                    handleScheduleChange({ e, title, type: 'open' })
                  }
                />
                <Select
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  defaultValue={availableTime.find((time) => time.value === `${title === 'Weekdays' ? schedule.weekdays.fromValue : schedule.weekends.fromValue}`)}
                  className="open-hours__select open-hours__select_sm"
                  options={availableTime}
                  onChange={(e) =>
                    handleScheduleValueChange({ e, title, type: 'open' })
                  }
                />
              </>
            ) : (
              renderMultipleSelects(true, 'Open')
            )}
          </div>
        </div>
        <div className="open-hours__select-block">
          {!switchDay && <h3 className="open-hours__label">Close</h3>}
          <div className="open-hours__select-section">
            {!switchDay ? (
              <>
                <Select
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  className="open-hours__select"
                  defaultValue={availableHours.find(
                    (hour) => hour.label === `${title === 'Weekdays' ? schedule.weekdays.toTime : schedule.weekends.toTime}`
                  )}
                  options={availableHours}
                  onChange={(e) =>
                    handleScheduleChange({ e, title, type: 'close' })
                  }
                />
                <Select
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  defaultValue={availableTime.find((time) => time.value === `${title === 'Weekdays' ? schedule.weekdays.toValue : schedule.weekends.toValue}`)}
                  className="open-hours__select open-hours__select_sm"
                  options={availableTime}
                  onChange={(e) =>
                    handleScheduleValueChange({ e, title, type: 'close' })
                  }
                />
              </>
            ) : (
              renderMultipleSelects(false, 'Close')
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

