import React from 'react';

import mastercardLogo from '../../../../assets/mastercard-logo.webp';
import visaLogo from '../../../../assets/visa-logo.webp';
import discoverLogo from '../../../../assets/discover-logo.webp';
import americanExpressLogo from '../../../../assets/american-express-logo.webp';
import ModalContainer from 'components/ModalContainer';
import styles from './AddPaymentMethodModal.module.scss';
import CardDetailsForm from '../CardDetailsForm';

const AddPaymentMethodModal = () => {
  return (
    <ModalContainer
      title="Add New Payment Method"
      bodyClassName={styles['add-payment-method-modal']}
      hasCancelButton
      onSubmit={() => {}}
    >
      <p>
        Your card details will be saved for future purchases and subscription
        renewals.
      </p>
      <ul className={styles.cards_list}>
        {[mastercardLogo, visaLogo, discoverLogo, americanExpressLogo].map(
          (logo, index) => (
            <li key={index}>
              <img src={logo} />
            </li>
          )
        )}
      </ul>
      <CardDetailsForm isTitleVisible />
    </ModalContainer>
  );
};

export default AddPaymentMethodModal;

