import React, { FC } from 'react';

import Section from '../Section';
import EditableInput from '../EditableInput';
import { ETabs } from 'components/Dashboard/types/Tabs.enum';
import styles from './BasicInfoSection.module.scss';
import { useModal } from 'store/modal';
import ChangeEmailModal from '../ChangeEmailModal';
import ChangePasswordModal from '../ChangePasswordModal';

interface IBasicInfoSectionProps {
  isActive: boolean;
}

const BasicInfoSection: FC<IBasicInfoSectionProps> = ({ isActive }) => {
  const modal = useModal();

  return (
    <Section title={ETabs.BASIC_INFO} isActive={isActive}>
      <div className={styles['basic-info-section']}>
        <EditableInput title="First Name" hasEdit />
        <EditableInput title="Last Name" hasEdit />
        <EditableInput
          title="Email"
          isConfirmed
          hasEdit
          onClick={() => modal.open(ChangeEmailModal)}
        />
        <EditableInput
          title="Password"
          hasEdit
          onClick={() => modal.open(ChangePasswordModal)}
        />
      </div>
    </Section>
  );
};

export default BasicInfoSection;

