import httpClient from 'api/http-client';
import {
  ILoginRequest,
  ILoginResponse,
  IRegisterRequest,
  IRegisterResponse,
} from 'types/auth';

const authService = {
  async register(registerData: IRegisterRequest): Promise<IRegisterResponse> {
    const { data } = await httpClient.post('/auth/register', {
      email: registerData.email,
      first_name: registerData.firstName,
      last_name: registerData.lastName,
      password: registerData.password,
    });

    return data;
  },
  async login(loginData: ILoginRequest): Promise<ILoginResponse> {
    const { data } = await httpClient.post('/auth/login', loginData);

    return data;
  },
  async refresh(refreshToken: string) {
    const { data } = await httpClient.post('/auth/refresh', {
      refresh_token: refreshToken,
    });

    return data;
  },
};

export default authService;

