import React, { FC } from 'react';
import classNames from 'classnames';

import { DASHBOARD_TABS } from 'components/Dashboard/const/tabs';
import { ETabs } from 'components/Dashboard/types/Tabs.enum';
import styles from './Tabs.module.scss';

interface ITabsProps {
  activeTab: ETabs;
  onTabChange: (tab: ETabs) => void;
}

const Tabs: FC<ITabsProps> = ({ activeTab, onTabChange }) => {
  return (
    <ul className={styles.tabs}>
      {DASHBOARD_TABS.map((tab) => (
        <li
          key={tab}
          className={classNames({
            [styles.active]: tab === activeTab,
          })}
        >
          <button onClick={() => onTabChange(tab)}>{tab}</button>
        </li>
      ))}
    </ul>
  );
};

export default Tabs;

