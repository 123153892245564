import React from 'react';

import Template from 'components/Template/Template';
import { Sidebar } from 'components/Editor';
import { useEditor } from 'store/editor';
import styles from './EditorPage.module.scss';

const EditorPage = () => {
  const { isEditing } = useEditor((state) => state);

  return (
    <div className={styles['editor-page']}>
      {isEditing && (
        <div className={styles['sidebar-container']}>
          <Sidebar />
        </div>
      )}

      <div className={styles['template-container']}>
        <Template />
      </div>
    </div>
  );
};

export default EditorPage;

