import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';

import styles from './LoginPage.module.scss';
import MinimalHeader from 'components/MinimalHeader';
import { Input } from 'components/UI/Input';
import { Button } from 'components/Button/Button';
import { useModal } from 'store/modal';
import ChangePasswordModal from 'components/Dashboard/components/ChangePasswordModal';
import Divider from 'components/UI/Divider';
import { useAuth } from 'store/auth';
import { ILoginRequest } from 'types/auth';

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

const LoginPage = () => {
  const navigate = useNavigate();
  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const modal = useModal();
  const Modal = modal.component;

  const { login, isLoggedIn } = useAuth();

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/dashboard');
    }
  }, [isLoggedIn, navigate])

  const handleResetPassword = () => {
    modal.open(ChangePasswordModal, { initialResetPassword: true })
  }

  const onSubmit = async (loginData: ILoginRequest) => {
    await login(loginData);
  }

  const { errors } = methods.formState;

  return (
    <div className={styles.login_page}>
      <MinimalHeader />
      <main>
        <h1>Welcome Back!</h1>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className={styles.form_fields}>
              <label>
                <span>Email</span>
                <Input isHookForm placeholder="Enter email" name="email" error={errors.email?.message} />
              </label>

              <label>
                <span>Password</span>
                <Input isHookForm type="password" placeholder="Enter password" name="password" error={errors.password?.message} />
              </label>
              <span className={styles.forgot_password}>
                Forgot your password?{' '}
                <button onClick={handleResetPassword} type='button'>
                  Reset
                </button>
              </span>
            </div>

            <Button title="Log In" type="submit" />
            <Divider />
            <Button title="Log In with Google" isOutlined />

            <span className={styles.sign_up}>Don't have an account? <Link to="/sign-up">Sign Up</Link></span>
            <p className={styles.terms}>
              By logging in you agree with the <span>Subscription Agreement</span> and <span>Privacy Statement</span>
            </p>
          </form>
        </FormProvider>
      </main>

      {Modal && <Modal {...modal.props} />}
    </div>
  )
}

export default LoginPage;
