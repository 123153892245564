import React, { FC } from 'react';
import cn from 'classnames';

import styles from './Switch.module.scss';

interface ISwitchProps {
  selectedIndex: number;
  left: JSX.Element | string;
  right: JSX.Element | string;
  onChange: (index: number) => void;
}

const Switch: FC<ISwitchProps> = ({ selectedIndex, left, right, onChange }) => {
  return (
    <div className={styles.switch}>
      {[left, right].map((item, index) => (
        <button
          key={index}
          onClick={() => onChange(index)}
          className={cn(styles.button, {
            [styles.active]: selectedIndex === index,
          })}
        >
          {item}
        </button>
      ))}
    </div>
  );
};

export default Switch;

