import React, { FC } from 'react';
import { TColors } from '../const';

interface IServiceProps {
  name: string;
  color: TColors;
  desc?: string[];
}

const Service: FC<IServiceProps> = ({ name, desc, color }) => {
  return (
    <>
      <label>
        <img src="/assets/example.png" alt="" />
        <p className={color}>
          {name}
          <ul className="hide">
            {desc?.map((string) => (
              <li>{string}</li>
            ))}
          </ul>
        </p>
      </label>
    </>
  );
};

export default Service;
