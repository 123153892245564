import React from 'react';
import './steps.scss';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { Button } from 'components/Button/Button';
import Template from 'components/Template/Template';

export const StepsLayout = () => {
  const navigate = useNavigate();

  const leavePage = () => {
    if (
      window.confirm('Do you want to leave the page? All changes will be lost.')
    ) {
      console.log(true);

      return navigate('/');
    }
  };

  return (
    <div className="steps__layout">
      <div className="steps__main">
        <header className="steps__header">
          <Link to="/">
            <img src="assets/idesn_logo.svg" alt="Logo" />
          </Link>
          <Button isOutlined onClick={leavePage} title="Save for later" />
        </header>
        <main>
          <Outlet />
        </main>
      </div>

      <div className="steps__template-container">
        <Template />
      </div>
      {/* <img className='steps__template' src='assets/template.png' alt='Template' /> */}
    </div>
  );
};
