import React from 'react'
import { ButtonLink } from 'components/Button/ButtonLink'

import './hero.scss'

export const Hero = () => {
    return (
        <>
            <section id="hero" className="o-hero">
                <div className="container">
                    <div className="o-hero-row">
                        <div className="left">
                            <h1 className="page-title">
                                Build Your Dental Clinic Website in Just 5
                                Minutes <em>with AI</em>
                            </h1>
                            <p className="page-subtitle">
                                Effortlessly create a professional and engaging
                                online presence for your dental practice using
                                our AI-powered website builder. No coding skills
                                required!
                            </p>
                            <ButtonLink
                                to="/step1"
                                title="Get Started For Free"
                            />
                        </div>
                        <div className="right"></div>
                    </div>
                </div>
            </section>
        </>
    )
}

