import React from 'react';
import { Link as LinkScroll } from 'react-scroll';

const Links = () => {
  const handleScrollTo = (sectionId: string) => {
    setTimeout(() => {
      const element = document.getElementById(sectionId);
      if (element) {
        window.scrollTo({
          top: element.offsetTop - 120,
          behavior: 'smooth',
        });
      }
    }, 400);
  };

  return (
    <ul>
      <li>
        <LinkScroll
          activeClass="active"
          to="main"
          spy={true}
          smooth={true}
          offset={-120}
          duration={500}
          onClick={() => handleScrollTo('main')}
        >
          Home
        </LinkScroll>
      </li>
      <li>
        <LinkScroll
          activeClass="active"
          to="about"
          spy={true}
          smooth={true}
          offset={-120}
          duration={500}
          onClick={() => handleScrollTo('about')}
        >
          About Us
        </LinkScroll>
      </li>
      <li>
        <LinkScroll
          activeClass="active"
          to="team"
          spy={true}
          smooth={true}
          offset={-120}
          duration={500}
          onClick={() => handleScrollTo('team')}
        >
          Our Team
        </LinkScroll>
      </li>
      <li>
        <LinkScroll
          activeClass="active"
          to="service"
          spy={true}
          smooth={true}
          offset={-120}
          duration={500}
          onClick={() => handleScrollTo('service')}
        >
          Our Services
        </LinkScroll>
      </li>
      <li>
        <LinkScroll
          activeClass="active"
          to="form"
          spy={true}
          smooth={true}
          offset={-120}
          duration={500}
          onClick={() => handleScrollTo('form')}
        >
          Contacts
        </LinkScroll>
      </li>
    </ul>
  );
};

export default Links;
