import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination, EffectFade } from 'swiper'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import 'swiper/css/pagination'
import 'swiper/css/effect-fade'
import './testimonials.scss'

SwiperCore.use([Pagination, EffectFade])
export const Testimonials = () => {
    return (
        <section id="testimonials" className="o-section o-testimonials">
            <div className="container">
                <div className="o-testimonials-content">
                    <div className="left">
                        <h2>
                            Smiles Created by i
                            <span className="e--blue">desn</span>: Dentists
                            Share Their <em>Success Stories</em>
                        </h2>
                    </div>
                    <div className="right">
                        <Swiper
                            className="o-testimonials-slider swiper"
                            style={{ overflow: 'visible' }}
                            speed={400}
                            spaceBetween={0}
                            slidesPerView={1}
                            effect="fade"
                            fadeEffect={{
                                crossFade: true,
                            }}
                            pagination={{ clickable: true }}
                            modules={[Pagination, EffectFade]}
                        >
                            <SwiperSlide className="swiper-slide o-testimonials-slide">
                                <div className="o-testimonials-slide__inner">
                                    <p className="text">
                                        Being a mom of two and managing my
                                        dental practice keeps me busy. I
                                        never thought creating a website
                                        could be this easy! Idesn took away
                                        all the technical hurdles and let me
                                        focus on presenting my practice in
                                        the best light. The whole experience
                                        was smooth, and the support team is
                                        just fantastic. Idesn is a must-try
                                        for every dental professional!
                                    </p>
                                    <div className="name">
                                        Dr. Jennifer Stevens
                                    </div>
                                    <div className="position">
                                        Dental Simple Care, New York
                                    </div>
                                    <div className="img">
                                        <img alt='Testimonial' src="/assets/testimonials/first.png" />
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="swiper-slide o-testimonials-slide">
                                <div className="o-testimonials-slide__inner">
                                    <p className="text">
                                        As a dentist who loves to spend
                                        weekends hiking in the mountains, I
                                        wanted a website builder that would
                                        make things quick and easy. Idesn
                                        did just that! The platform is
                                        incredibly user-friendly, and it let
                                        me customize every aspect of my site
                                        without any hassle. My website now
                                        looks great on all devices, and
                                        I&#39;ve seen a significant
                                        improvement in search engine
                                        rankings. Idesn, you&#39;re a
                                        lifesaver!
                                    </p>
                                    <div className="name">
                                        Dr. Michael Adams
                                    </div>
                                    <div className="position">
                                        Adams Orthodontics, Seattle
                                    </div>
                                    <div className="img">
                                        <img alt='Testimonial' src="/assets/testimonials/second.png" />
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="swiper-slide o-testimonials-slide">
                                <div className="o-testimonials-slide__inner">
                                    <p className="text">
                                        After 20 years in the dental field,
                                        I decided to give my clinic&#39;s
                                        website a makeover. I recently
                                        revamped it using Idesn, and I&#39;m
                                        amazed by the results! The entire
                                        process was a breeze, and I
                                        didn&#39;t need any coding skills to
                                        make it happen. The free hosting and
                                        custom domain features are the
                                        cherry on top. I can&#39;t recommend
                                        Idesn enough to other dentists
                                        looking to level up their websites.
                                    </p>
                                    <div className="name">
                                        Dr. Sandra Williams
                                    </div>
                                    <div className="position">
                                        Bright Dental, Miami
                                    </div>
                                    <div className="img">
                                        <img alt='Testimonial' src="/assets/testimonials/third.png" />
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="swiper-slide o-testimonials-slide">
                                <div className="o-testimonials-slide__inner">
                                    <p className="text">
                                        As a dentist who enjoys photography
                                        in my spare time, I wanted my
                                        website to reflect my passion for
                                        aesthetics. Idesn exceeded my
                                        expectations! The platform is a
                                        dream to use, and my website looks
                                        better than ever. Plus, it&#39;s
                                        mobile-responsive and SEO-friendly.
                                        What more could a dentist ask for?
                                    </p>
                                    <div className="name">
                                        Dr. Jason Lee
                                    </div>
                                    <div className="position">
                                        Lee Dental Clinic, Los Angeles
                                    </div>
                                    <div className="img">
                                        <img alt='Testimonial' src="/assets/testimonials/fourth.png" />
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="swiper-slide o-testimonials-slide">
                                <div className="o-testimonials-slide__inner">
                                    <p className="text">
                                        Managing a bustling dental practice
                                        in the heart of Chicago is no small
                                        task, but using Idesn for my website
                                        has been a game-changer. The
                                        platform is so intuitive, and I was
                                        able to create a stunning website
                                        without any web design experience. I
                                        wholeheartedly endorse Idesn to any
                                        dentist looking to build or improve
                                        their website.
                                    </p>
                                    <div className="name">
                                        Dr. Emily Johnson
                                    </div>
                                    <div className="position">
                                        Johnson & Associates Dental, Chicago
                                    </div>
                                    <div className="img">
                                        <img alt='Testimonial' src="/assets/testimonials/fifth.png" />
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </div>
        </section>
    )
}
