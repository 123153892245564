import { Link } from 'react-router-dom'
import React from 'react'
import Menu from 'components/Menu/Menu'

import './footer.scss'

export const Footer = () => {
    return (
        <>
            <footer className="o-footer o-section">
                <div className="container">
                    <div className="o-footer-row">
                        <Link to="/" className="a-logo">
                            <img alt='Logo' src="/assets/idesn.svg" />
                        </Link>
                        <a className="mail" href="mailto:info@idesn.com">
                            info@idesn.com
                        </a>
                        <Menu />
                        <div className="bottom">
                            <p>
                                {new Date().getFullYear()} © idesn. All Rights Reserved. Copying of
                                texts or images, dissemination of information in
                                any form is prohibited without the written
                                consent of idesn.
                            </p>
                            <div className="links">
                                <Link to="/terms">Terms and Conditions</Link>
                                <Link to="/privacy">Privacy Policy</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

