import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';

import MinimalHeader from 'components/MinimalHeader'
import { Input } from 'components/UI/Input'
import { Button } from 'components/Button/Button'
import Divider from 'components/UI/Divider'
import styles from './SignUpPage.module.scss'
import { FormProvider, useForm } from 'react-hook-form';
import { IRegisterRequest } from 'types/auth';
import { useAuth } from 'store/auth';

const schema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().email().required(),
  password: yup.string().min(8).max(32).required(),
});

const SignUpPage = () => {
  const navigate = useNavigate();
  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const { register, isLoggedIn } = useAuth();

  const onSubmit = async (registerData: IRegisterRequest) => {
    await register(registerData);
  }

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/dashboard');
    }
  }, [isLoggedIn, navigate]);

  const { errors } = methods.formState;

  return (
    <div className={styles.signup_page}>
      <MinimalHeader />
      <main>
        <h1>Sign Up</h1>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className={styles.form_fields}>
              <div className={styles.row}>
                <label>
                  <span>First Name</span>
                  <Input isHookForm placeholder='Enter First Name' name="firstName" error={errors.firstName?.message} />
                </label>
                <label>
                  <span>Last Name</span>
                  <Input isHookForm placeholder='Enter Last Name' name="lastName" error={errors.lastName?.message} />
                </label>
              </div>
              <label>
                <span>Email</span>
                <Input isHookForm placeholder='Enter Email' name="email" error={errors.email?.message} />
              </label>
              <label>
                <span>Password</span>
                <Input isHookForm placeholder='Enter Password' name="password" error={errors.password?.message} />
              </label>
            </div>

            <Button title='Sign Up' type="submit" />
            <Divider />
            <Button title='Sign Up with Google' isOutlined />

            <span className={styles.sign_in}>
              Have an account? <Link to='/login'>Log In</Link>
            </span>
            <p className={styles.terms}>
              By continuing you agree with the <span>Terms and Conditions</span> and <span>Privacy Policy</span>
            </p>
          </form>
        </FormProvider>
      </main>
    </div>
  )
}

export default SignUpPage
