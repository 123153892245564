import {
  Address,
  Colors,
  Contact,
  DefaultSchedule,
  Photos,
  Service,
  Social,
  StaffMember,
} from 'types/steps';
import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

interface FirstStepState {
  name: string;
  setFirstStep: (name: string, slogan: string) => void;
  slogan: string;
  getName: () => string;
  getSlogan: () => string;
}

interface SecondStepState {
  colors: Colors;
  setSecondStep: (colors: Colors) => void;
  getColors: () => object;
}

interface ThirdStepState {
  photos: Photos;
  setThirdStep: (photos: Photos) => void;
  getPhotos: () => Photos;
}

interface FourthStepState {
  services: Service[];
  setFourthStep: (services: Service[]) => void;
  getServices: () => Service[];
}

interface SixthStepState {
  team: StaffMember[];
  setSixthStep: (team: StaffMember[]) => void;
  getTeam: () => StaffMember[];
}

interface SeventhStepState {
  schedule: DefaultSchedule;
  setSeventhStep: (schedule: DefaultSchedule) => void;
  getSchedule: () => DefaultSchedule;
}

interface EighthStepState {
  address: Address;
  setEighthStep: (address: Address) => void;
  getAddress: () => Address;
}

interface NinethStepState {
  contact: Contact;
  setNinethStep: (contact: Contact) => void;
  getContact: () => Contact;
}

interface TenthStepState {
  social: Social;
  setTenthStep: (social: Social) => void;
  getSocial: () => Social;
}

export const useFirstStepStore = create<FirstStepState>()(
  persist(
    (set, get) => ({
      name: '',
      slogan: '',
      setFirstStep: (name, slogan) =>
        set(() => ({ name: name, slogan: slogan })),
      getName: () => get().name,
      getSlogan: () => get().slogan,
    }),
    {
      name: 'name', // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
    }
  )
);

export const useSecondStepStore = create<SecondStepState>()(
  persist(
    (set, get) => ({
      colors: { main: '', primary: '', name: 'color1' },
      setSecondStep: (pickedColors) =>
        set(() => ({
          colors: {
            main: pickedColors.main,
            primary: pickedColors.primary,
            name: pickedColors.name,
          },
        })),
      getColors: () => get().colors,
    }),
    {
      name: 'colors',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

export const useThirdStepStore = create<ThirdStepState>()(
  persist(
    (set, get) => ({
      photos: { first: '', second: '' },
      setThirdStep: (photos) =>
        set(() => ({
          photos,
        })),
      getPhotos: () => get().photos,
    }),
    {
      name: 'photos',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

export const useFourthStepStore = create<FourthStepState>()(
  persist(
    (set, get) => ({
      services: [{ name: '', description: [''] }],
      setFourthStep: (services) =>
        set(() => ({
          services: services,
        })),
      getServices: () => get().services,
    }),
    {
      name: 'services',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

export const useSixthStepStore = create<SixthStepState>()(
  persist(
    (set, get) => ({
      team: [{ name: '', title: '', photo: '' }],
      setSixthStep: (team) =>
        set(() => ({
          team,
        })),
      getTeam: () => get().team,
    }),
    {
      name: 'team',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

export const useSeventhStepStore = create<SeventhStepState>()(
  persist(
    (set, get) => ({
      schedule: {
        weekdays: { fromTime: '', fromValue: '', toTime: '', toValue: '' },
        weekends: { fromTime: '', fromValue: '', toTime: '', toValue: '' },
      },
      setSeventhStep: (schedule) =>
        set(() => ({
          schedule,
        })),
      getSchedule: () => get().schedule,
    }),
    {
      name: 'schedule',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

export const useEighthStepStore = create<EighthStepState>()(
  persist(
    (set, get) => ({
      address: { country: '', state: '', city: '', address: '' },
      setEighthStep: (address) =>
        set(() => ({
          address: {
            country: address.country,
            state: address.state,
            city: address.city,
            address: address.address,
          },
        })),
      getAddress: () => get().address,
    }),
    {
      name: 'address',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

export const useNinethStepStore = create<NinethStepState>()(
  persist(
    (set, get) => ({
      contact: { number: '', email: '' },
      setNinethStep: (contact) =>
        set(() => ({
          contact: { number: contact.number, email: contact.email },
        })),
      getContact: () => get().contact,
    }),
    {
      name: 'contact',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

export const useTenthStepStore = create<TenthStepState>()(
  persist(
    (set, get) => ({
      social: { facebook: '', instagram: '', linkedIn: '' },
      setTenthStep: (social) =>
        set(() => ({
          social: {
            facebook: social.facebook,
            instagram: social.instagram,
            linkedIn: social.linkedIn,
          },
        })),
      getSocial: () => get().social,
    }),
    {
      name: 'social',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

