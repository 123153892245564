import { ButtonLink } from 'components/Button/ButtonLink';
import { ProgressBar } from 'components/ProgressBar/ProgressBar';
import { Input } from 'components/UI/Input';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useEighthStepStore } from 'store/steps';

const countryOptions = [
  { value: 'US', label: 'United States' },
  { value: 'UK', label: 'United Kingdom' },
];

const stateOptions = [
  { value: 'CA', label: 'California' },
  { value: 'OI', label: 'Ohio' },
];

const cityOptions = [
  { value: 'NY', label: 'New York' },
  { value: 'LA', label: 'Los Angeles' },
];

const Step8 = () => {
  const addressState = useEighthStepStore().getAddress;
  const setAddressState = useEighthStepStore().setEighthStep;
  const [address, setAddress] = useState(addressState);

  console.log(address);
  
  const handleAddressOption = ({e, value}: {e: any, value: string}) => {
    setAddress((prev) => ({...prev, [value]: e.value}))
  };

  const handleAddressChange = (e: any) => {
    setAddress((prev) => ({...prev, address: e.target.value}))
  };

  useEffect(() => {
    setAddressState(address)
  }, [address])
  return (
    <>
      <div className="steps__container">
        <h2 className="steps__heading">
          What is the full address of your dental clinic?
        </h2>
        <div className="steps__select-container">
          <div>
            <h3>Country</h3>
            <Select
              placeholder="Choose Your Country"
              options={countryOptions}
              onChange={(e) => handleAddressOption({e, value: 'country'})}
              defaultValue={address && countryOptions.find(
                (country) => country.label === address.country
              )}
            />
          </div>
          <div>
            <h3>State</h3>
            <Select
              placeholder="Choose Your State"
              options={stateOptions}
              onChange={(e) => handleAddressOption({e, value: 'state'})}
              defaultValue={address && stateOptions.find(
                (country) => country.label === address.state
              )}
            />
          </div>
          <div>
            <h3>City</h3>
            <Select
              placeholder="Choose Your City"
              options={cityOptions}
              onChange={(e) => handleAddressOption({e, value: 'city'})}
              defaultValue={address && cityOptions.find(
                (country) => country.label === address.city
              )}
            />
          </div>
          <div>
            <h3>Enter Your Address</h3>
            <Input
              placeholder="Choose Your Address"
              onChange={(e) => handleAddressChange(e)}
              value={address.address}
            />
          </div>
        </div>
      </div>
      <ProgressBar step={8} />
      <div className="steps__multi-container">
        <ButtonLink underline isOutlined to="/step7" title="Back" />
        <ButtonLink isOutlined to="/step9" title="Next" />
      </div>
    </>
  );
};

export default Step8;

