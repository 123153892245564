import { Link, useNavigate } from 'react-router-dom';
import React from 'react';
import { useEffect, useState } from 'react';
import { Button } from 'components/Button/Button';
import Menu from 'components/Menu/Menu';
import { ButtonLink } from 'components/Button/ButtonLink';
import BurgerMenu from 'components/BurgerMenu/BurgerMenu';
import './header.scss';
import { useAuth } from 'store/auth';

export const Header = ({
  type,
  buttonSave,
}: {
  type?: string;
  buttonSave?: boolean;
}) => {
  const navigate = useNavigate();

  const [scrolled, setScrolled] = useState(false);

  const { isLoggedIn, logout } = useAuth();

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrolled]);
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  function closeMenu() {
    setIsOpen(false);
  }

  return (
    <>
      {typeof type !== 'undefined' ? (
        <header
          className={scrolled ? `active o-header ${type}` : `o-header ${type}`}
        >
          <div className="container">
            <div className="o-header-row">
              <div className="left">
                <Link to="/" className="logo">
                  <img alt="Logo" src="/assets/idesn_logo.svg" />
                </Link>
              </div>
              {buttonSave ? (
                <Button color="transparent" title="Save For Later" />
              ) : (
                ''
              )}
            </div>
          </div>
        </header>
      ) : (
        <>
          <header className={scrolled ? `active o-header ` : `o-header `}>
            <div className="container">
              <div className="o-header-row">
                <div className="left">
                  <Link to="/" className="logo">
                    <img alt="Logo" src="/assets/idesn_logo.svg" />
                  </Link>
                </div>
                <div className="right e--desk">
                  <Menu />
                  {isLoggedIn ? (
                    <button
                      className="a-btn transparent"
                      onClick={logout}
                      color="transparent"
                    >
                      Exit
                    </button>
                  ) : (
                    <button className="a-btn transparent" onClick={() => navigate('/login')}>
                      Log In
                    </button>
                  )}
                  <ButtonLink
                    color="blue"
                    title="Get Started For Free"
                    to="/step1"
                  />
                </div>
                <div className="right e--mobile">
                  <button
                    className={isOpen ? `m-burger-btn active` : 'm-burger-btn'}
                    onClick={toggleMenu}
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                  </button>
                </div>
              </div>
            </div>
          </header>
          {isOpen ? <BurgerMenu closeMenu={closeMenu} /> : ''}
        </>
      )}
    </>
  );
};
