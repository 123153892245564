import { Configuration, PublicClientApplication } from '@azure/msal-browser';

export const b2cPolicies = {
  names: {
    signUpSignIn: 'B2C_1_SignUpSignIn',
    editProfile: 'B2C_1_EditProfile',
  },
  authorities: {
    signUpSignIn: {
      authority:
        'https://idesndev.b2clogin.com/idesndev.onmicrosoft.com/B2C_1_SignUpSignIn',
    },
    editProfile: {
      authority:
        'https://idesndev.b2clogin.com/idesndev.onmicrosoft.com/b2c_1_edit_profile_v2',
    },
  },
  authorityDomain: 'idesndev.b2clogin.com',
};

export const msalConfig: Configuration = {
  auth: {
    clientId: '9193b39d-6240-4bca-8245-768a90a704cd',
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: '/',
  },
};

export const protectedResources = {
  api: {
    endpoint: 'http://localhost:8080',
    scopes: ['openid', 'email', 'offline_access'],
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);
