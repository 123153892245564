import axios from 'axios';

import { getAuthData, saveAuthData } from 'helpers/authStorage';
import authService from './services/authService';

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

httpClient.interceptors.request.use(
  (config) => {
    const customConfig = { ...config };
    const { accessToken } = getAuthData();

    if (accessToken) {
      customConfig.headers.Authorization = `Bearer ${accessToken}`;
    }

    return customConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);

httpClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      const { refreshToken } = getAuthData();

      if (!refreshToken) {
        return Promise.reject(error);
      }

      try {
        const refreshedAccessToken = await authService.refresh(refreshToken);

        saveAuthData(refreshedAccessToken, refreshToken);

        return httpClient(originalRequest);
      } catch (error) {
        return Promise.reject(error);
      }
    }
  }
);

export default httpClient;

