import { ButtonLink } from 'components/Button/ButtonLink';
import { ProgressBar } from 'components/ProgressBar/ProgressBar';
import { Input } from 'components/UI/Input';
import React, { useEffect, useState } from 'react'
import { useFirstStepStore } from 'store/steps';

const Step1 = () => {
    const storeName = useFirstStepStore((state) => state.getName())
    const storeSlogan = useFirstStepStore((state) => state.getSlogan())
    const setGlobalName = useFirstStepStore((state) => state.setFirstStep);
    const [name, setName] = useState(storeName);
    const [slogan, setSlogan] = useState(storeSlogan);

    const submitNameChange = (event: any) => {
        setName(event);
    }

    const submitSloganChange = (event: any) => {
        setSlogan(event)
    }

    useEffect(() => {
        setGlobalName(name, slogan);
    }, [name, slogan])

    return (
        <>
            <div className='steps__container'>
                <h2 className='steps__heading'>Let’s Start Creating Your Website</h2>
                <h3 className='steps__label'>What is the name of your dental clinic?</h3>
                <Input onChange={(event) => submitNameChange(event?.target.value)} value={name} placeholder='Enter Your Clinic Name' className='steps__input' />
                <h3 className='steps__label'>A brief description (e.g., years in business, mission, unique selling points)</h3>
                <Input onChange={(event) => submitSloganChange(event?.target.value)} value={slogan} isTextArea placeholder='Enter Your Description' className='steps__input steps__textarea' />
            </div>

            <ProgressBar step={1} />
            <div className='steps__container'>
                <ButtonLink isOutlined to='/step2' className='steps__button' title='Next' />
            </div>
        </>
    )
}

export default Step1