import { Button } from 'components/Button/Button'
import { ButtonLink } from 'components/Button/ButtonLink'
import { ProgressBar } from 'components/ProgressBar/ProgressBar'
import { Input } from 'components/UI/Input'
import React, { useEffect, useState } from 'react'
import { useTenthStepStore } from 'store/steps'



const Step10 = () => {
    const socialState = useTenthStepStore().getSocial
    const setSocialState = useTenthStepStore().setTenthStep
    const [social, setSocial] = useState(socialState)

    const handleChange = ({e, value}: {e: any, value: string}) => {
        setSocial((prev) => ({...prev, [value]: e.target.value}))
    }

    useEffect(() => {
        setSocialState(social)
    }, [social])
    return (
        <>
            <div className='steps__container'>
                <h2 className='steps__heading'>What are your clinic's phone number, email address, and any other relevant contact details?</h2>
                <div className='steps__details-container'>

                    <div>
                        <h3>Facebook</h3>
                        <Input placeholder='Enter The Link' onChange={(e) => handleChange({e, value: 'facebook'})} value={social.facebook} />
                    </div>
                    <div>
                        <h3>Instagram</h3>
                        <Input placeholder='Enter The Link' onChange={(e) => handleChange({e, value: 'instagram'})} value={social.instagram} />
                    </div>
                    <div>
                        <h3>Linkedin</h3>
                        <Input placeholder='Enter The Link' onChange={(e) => handleChange({e, value: 'linkedIn'})} value={social.linkedIn}/>
                    </div>
                </div>
            </div>
            <ProgressBar step={10} />
            <div className='steps__multi-container'>
                <ButtonLink underline isOutlined to='/step9' title='Back' />
                <Button clear isOutlined title='Skip' />
                <ButtonLink isOutlined to="/step11" title='Next' />
            </div>
        </>
    )
}

export default Step10