import React, { FC } from 'react';

import Section from '../Section';
import { ETabs } from 'components/Dashboard/types/Tabs.enum';
import { Button } from 'components/Button/Button';
import { MdOpenInNew } from 'react-icons/md';
import styles from './WebsiteSection.module.scss';
import { useNavigate } from 'react-router-dom';
import { useModal } from 'store/modal';
import ChooseDomainModal from '../ChooseDomainModal';

interface IWebsiteSectionProps {
  isActive: boolean;
  websiteName: string;
}

const WebsiteSection: FC<IWebsiteSectionProps> = ({
  isActive,
  websiteName,
}) => {
  const navigate = useNavigate();
  const modal = useModal();

  return (
    <Section title={ETabs.YOUR_WEBSITE} isActive={isActive}>
      <div className={styles['website-section']}>
        <img src="/assets/template.png" alt="website preview" />
        <div className={styles['website-section__info']}>
          <div className={styles['website-section__info--text']}>
            <h4>{websiteName}</h4>
            <p>
              <span>Domain</span>
              <a href="">
                artsmile.idesn.com <MdOpenInNew />
              </a>
            </p>
            <p>
              <span>Suggested Domain</span>
              <a href="">artsmile.com</a>
            </p>
          </div>
          <div className={styles['website-section__info--buttons']}>
            <Button title="Edit Website" onClick={() => navigate('/editor')} />
            <Button
              title="Choose Domain"
              onClick={() => modal.open(ChooseDomainModal)}
              isOutlined
              color="transparent"
            />
          </div>
        </div>
      </div>
    </Section>
  );
};

export default WebsiteSection;

