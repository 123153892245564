import React, { useEffect, useState } from 'react';
import { Button } from 'components/Button/Button';
import { ButtonLink } from 'components/Button/ButtonLink';
import { ProgressBar } from 'components/ProgressBar/ProgressBar';
import { StaffSuggestion } from 'components/StuffInput/StaffSuggestion';
import { StaffMember } from 'types/steps';
import { useSixthStepStore } from 'store/steps';

// const DEFAULT_STAFF_AMOUNT: StaffMember[] = [
//   { name: '', title: '', photo: '' },
//   { name: '', title: '', photo: '' },
//   { name: '', title: '', photo: '' },
// ];

const Step6 = () => {
    const setStateMembers = useSixthStepStore().setSixthStep
    const staffState = useSixthStepStore().getTeam
  const [staff, setStaff] = useState<StaffMember[]>(staffState);

  const addStaff = () =>
    setStaff((prev) => {
      return [...prev, { name: '', title: '', photo: '' }];
    });

    useEffect(() => {
        setStateMembers(staff)
    }, [staff])

  return (
    <>
      <div className="steps__container">
        <h2 className="steps__heading">
          Check a description of each service you offer
        </h2>
        {staff.map(({ name, title, photo }, index) => (
          <StaffSuggestion
            key={index}
            setStaff={setStaff}
            staff={staff}
            name={name}
            title={title}
            photo={photo}
            number={index + 1}
          />
        ))}
        <Button
          onClick={addStaff}
          isOutlined
          clear
          title="+ Add more services"
        />
      </div>
      <ProgressBar step={6} />
      <div className="steps__multi-container">
        <ButtonLink underline isOutlined to="/step5" title="Back" />
        <Button clear isOutlined title="Skip" />
        <ButtonLink isOutlined to="/step7" title="Next" />
      </div>
    </>
  );
};

export default Step6;
