import { ButtonLink } from 'components/Button/ButtonLink';
import { OpenHours } from 'components/OpenHours/OpenHours';
import { ProgressBar } from 'components/ProgressBar/ProgressBar';
import React, { useEffect, useState } from 'react';
import { useSeventhStepStore } from 'store/steps';

const Step7 = () => {
  const scheduleState = useSeventhStepStore().getSchedule;
  const setScheduleState = useSeventhStepStore().setSeventhStep;
  const [schedule, setSchedule] = useState(scheduleState);

  useEffect(() => {
    setScheduleState(schedule);
  }, [schedule]);

  return (
    <>
      <div className="steps__container">
        <h2 className="steps__heading">
          What are your clinic's regular operating hours?
        </h2>
        <OpenHours title="Weekdays" setSchedule={setSchedule} schedule={schedule}/>
        <OpenHours title="Weekends" setSchedule={setSchedule} schedule={schedule}/>
      </div>
      <ProgressBar step={7} />
      <div className="steps__multi-container">
        <ButtonLink underline isOutlined to="/step6" title="Back" />
        <ButtonLink isOutlined to="/step8" title="Next" />
      </div>
    </>
  );
};

export default Step7;
