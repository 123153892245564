import { create } from 'zustand';

import authService from 'api/services/authService';
import { clearAuthData, getAuthData, saveAuthData } from 'helpers/authStorage';
import { ILoginRequest, IRegisterRequest, IUser } from 'types/auth';

interface AuthState {
  currentUser: IUser | null;
  isLoggedIn: boolean;
  setIsLoggedIn: (isLoggedIn: boolean) => void;
  login: (data: ILoginRequest) => Promise<void>;
  register: (data: IRegisterRequest) => Promise<void>;
  checkAuth: () => Promise<void>;
  logout: () => void;
}

export const useAuth = create<AuthState>((set) => ({
  currentUser: null,
  isLoggedIn: false,
  setIsLoggedIn: (isLoggedIn: boolean) => set({ isLoggedIn }),
  login: async (loginData: ILoginRequest) => {
    try {
      const {
        user: currentUser,
        access_token: accessToken,
        refresh_token: refreshToken,
      } = await authService.login(loginData);

      if (!currentUser || !accessToken || !refreshToken) {
        return;
      }

      saveAuthData(accessToken, refreshToken);
      set({ currentUser, isLoggedIn: true });
    } catch (error) {
      console.log(error);
    }
  },
  register: async (registerData: IRegisterRequest) => {
    try {
      const {
        user: currentUser,
        access_token: accessToken,
        refresh_token: refreshToken,
      } = await authService.register(registerData);

      if (!currentUser || !accessToken || !refreshToken) {
        return;
      }

      saveAuthData(accessToken, refreshToken);
      set({ currentUser, isLoggedIn: true });
    } catch (error) {
      console.log(error);
    }
  },
  checkAuth: async () => {
    const { refreshToken } = getAuthData();

    try {
      const { user: currentUser, access_token: accessToken } =
        await authService.refresh(refreshToken);

      if (!currentUser || !accessToken) {
        return;
      }

      saveAuthData(accessToken, refreshToken);
      set({ currentUser, isLoggedIn: true });
    } catch (error) {
      set({ currentUser: null, isLoggedIn: false });
    }
  },
  logout: () => {
    clearAuthData();
    set({ currentUser: null, isLoggedIn: false });
  },
}));

