import React from 'react';
import { Link } from 'react-router-dom';
import { MdSearch } from 'react-icons/md';

import ModalContainer from 'components/ModalContainer';
import { Input } from 'components/UI/Input';
import styles from './ChooseDomainModal.module.scss';

const ChooseDomainModal = () => {
  return (
    <ModalContainer
      title="Choose domain for your website"
      bodyClassName={styles['choose-domain-modal']}
    >
      <div className={styles.search}>
        <Input placeholder="Enter Domain" className={styles.domain_input} />
        <button className={styles.search_button}>
          <MdSearch />
        </button>
      </div>
      <div className={styles.suggested_domains}>
        <h3>Suggested Domains</h3>
        <ul>
          {['artsmile.com', 'artsmile-medical.com', 'artsmileclinic.com'].map(
            (domain) => (
              <li key={domain}>
                <span>{domain}</span>
                <Link to="/pricing">Get It</Link>
              </li>
            )
          )}
        </ul>
      </div>
    </ModalContainer>
  );
};

export default ChooseDomainModal;

