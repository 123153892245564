import React, { FC } from 'react';

import { AiOutlineClockCircle, AiOutlineMail } from 'react-icons/ai';
import { MdOutlinePhone } from 'react-icons/md';

import { COLOR_MAP } from 'components/Template/const';
import { useFirstStepStore, useSeventhStepStore } from 'store/steps';
import Links from '../Links';

type THeaderProps = {
  color: keyof typeof COLOR_MAP;
};

const Header: FC<THeaderProps> = ({ color }) => {
  const storeName = useFirstStepStore((state) => state.getName());
  const storeSchedule = useSeventhStepStore((state) => state.getSchedule());

  return (
    <header>
      <div className="steps__template__header__details">
        <div className="steps__template__header__details__worktime">
          <AiOutlineClockCircle />
          <p>
            Mon-Fri:{' '}
            {storeSchedule.weekdays.fromTime !== ''
              ? storeSchedule.weekdays.fromTime
              : '9:00'}{' '}
            {storeSchedule.weekdays.fromValue !== ''
              ? storeSchedule.weekdays.fromValue
              : 'AM'}{' '}
            -{' '}
            {storeSchedule.weekdays.toTime !== ''
              ? storeSchedule.weekdays.toTime
              : '7:00'}
            {storeSchedule.weekdays.toValue !== ''
              ? storeSchedule.weekdays.toValue
              : 'AM'}
          </p>
          <p>
            Sat:{' '}
            {storeSchedule.weekends.fromTime !== ''
              ? storeSchedule.weekends.fromTime
              : '9:00'}{' '}
            {storeSchedule.weekends.fromValue !== ''
              ? storeSchedule.weekends.fromValue
              : 'AM'}{' '}
            -{' '}
            {storeSchedule.weekends.toTime !== ''
              ? storeSchedule.weekends.toTime
              : '7:00'}{' '}
            {storeSchedule.weekends.toValue !== ''
              ? storeSchedule.weekends.toValue
              : 'AM'}
          </p>
        </div>
        <div className="steps__template__header__details__contact">
          <MdOutlinePhone />
          <p>1-800-123-4567</p>
          <AiOutlineMail />
          <p>dentalplus-medical@gmail.com</p>
        </div>
      </div>
      <div className="steps__template__header__main">
        <h2 className="steps__template__header__main__logo">
          {storeName ? (
            <>
              <span>{storeName}</span>
            </>
          ) : (
            <>
              <span className={color ? color : 'color1'}>Art</span>
              <span>Smile</span>
            </>
          )}
        </h2>
        <div className="steps__template__header__main__navigation">
          <nav>
            <Links />
          </nav>
          <button
            className="a-btn blue steps__template__header__main__navigation__button"
            style={{
              backgroundColor: COLOR_MAP[color],
            }}
          >
            Make an Appointment
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;

