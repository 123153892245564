import { Button } from 'components/Button/Button';
import { ButtonLink } from 'components/Button/ButtonLink';
import { ProgressBar } from 'components/ProgressBar/ProgressBar';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useThirdStepStore } from 'store/steps';
import { Photos } from 'types/steps';

export const Step3 = () => {
  const setStatePhotos = useThirdStepStore().setThirdStep;
  const getStatePhotos = useThirdStepStore().getPhotos();
  const [photos, setPhotos] = useState<Photos>(getStatePhotos);

  console.log(photos);
  

  useEffect(() => {
    setStatePhotos(photos);
  }, [photos]);

  function handleChange({ target }: ChangeEvent<HTMLInputElement>) {
    const files = target.files;

    if (files) {
      if (photos.first === '' && photos.second === '') {
        setPhotos((prev) => ({
          ...prev,
          first: URL.createObjectURL(files[0]),
          second: URL.createObjectURL(files[1])
        }));
      } else if (photos.second === '') {
        setPhotos((prev) => ({
          ...prev,
          second: URL.createObjectURL(files[0]),
        }));
      } else if (photos.first === '') {
        setPhotos((prev) => ({
          ...prev,
          first: URL.createObjectURL(files[0]),
        }));
      } else {
        alert('You can upload a maximum of 2 photos.');
      }
    }
  }

  const removeImage = (key: 'first' | 'second') => {
    const updatedPhotos = { ...photos };
    updatedPhotos[key] = '';
    setPhotos(updatedPhotos);
  };

  return (
    <>
      <div className="steps__container">
        <h2 className="steps__heading">
          Can you provide high-quality photos of your dental clinic, including
          the interior, exterior, and team members? (Up to 2)
        </h2>
        <div className="steps__photo-preview-container">
          {photos.first !== '' && (
            <div className="steps__image-block">
              <img
                className="steps__photo-preview"
                alt="First"
                src={photos.first}
              />
              <span
                onClick={() => removeImage('first')}
                className="steps__photo-delete"
              >
                X
              </span>
            </div>
          )}
          {photos.second !== '' && (
            <div className="steps__image-block">
              <img
                className="steps__photo-preview"
                alt="Second"
                src={photos.second}
              />
              <span
                onClick={() => removeImage('second')}
                className="steps__photo-delete"
              >
                X
              </span>
            </div>
          )}
        </div>
        <div className="steps__photo-choice">
          <div className="steps__photo">
            <input
              onChange={handleChange}
              multiple
              accept="image/*"
              className="steps__photo-input"
              type="file"
            />
            <Button isOutlined title="Upload photos" />
          </div>
          <span>or</span>
          <Button isOutlined title="Generate with AI" />
        </div>
      </div>
      <ProgressBar step={3} />
      <div className="steps__multi-container">
        <ButtonLink underline isOutlined to="/step2" title="Back" />
        <Button clear isOutlined title="Skip" />
        <ButtonLink isOutlined to="/step4" title="Next" />
      </div>
    </>
  );
};

export default Step3;
