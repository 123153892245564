import React, { FC, useState } from 'react';

import Section from '../Section';
import styles from './PaymentSection.module.scss';
import { ETabs } from 'components/Dashboard/types/Tabs.enum';
import PaymentMethod from '../PaymentMethod';

interface IPaymentSectionProps {
  isActive: boolean;
}

const PaymentSection: FC<IPaymentSectionProps> = ({ isActive }) => {
  const [mockedPaymentMethods, setMockedPaymentMethods] = useState([
    {
      lastFourDigits: '7890',
      expirationDate: '11/30',
      cardType: 'mastercard',
    },
    {
      lastFourDigits: '2974',
      expirationDate: '09/16',
      cardType: 'visa',
    },
  ]);

  const handleDelete = (index: number) => {
    const updatedPaymentMethods = [...mockedPaymentMethods];
    updatedPaymentMethods.splice(index, 1);
    setMockedPaymentMethods(updatedPaymentMethods);
  };

  return (
    <Section title={ETabs.PAYMENT_METHODS} isActive={isActive}>
      <ul className={styles.list}>
        {mockedPaymentMethods.map((paymentMethod, index) => (
          <li key={index}>
            <PaymentMethod
              lastFourDigits={paymentMethod.lastFourDigits}
              expirationDate={paymentMethod.expirationDate}
              cardType={paymentMethod.cardType as 'mastercard' | 'visa'}
              onRemove={() => handleDelete(index)}
              isActive={index === 0}
            />
          </li>
        ))}
        <li>
          <PaymentMethod isPlaceholder />
        </li>
      </ul>
    </Section>
  );
};

export default PaymentSection;

