import { RefObject, useEffect } from 'react';

export default function useClickOutside(
  ref: RefObject<HTMLElement>,
  handler: () => void,
  excludeRef: RefObject<HTMLElement> | null = null
) {
  useEffect(() => {
    const listener = (event: any) => {
      if (
        !ref.current ||
        ref.current.contains(event.target) ||
        (excludeRef && excludeRef?.current?.contains(event.target))
      ) {
        return;
      }

      handler();
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler, excludeRef]);
}

