import React, { FC } from 'react';

import { Button } from 'components/Button/Button';
import DevicePicker from '../DevicePicker';
import Logo from 'components/UI/Logo';
import UserMenu from '../UserMenu';

import { useEditor } from 'store/editor';

import styles from './Header.module.scss';

interface IHeaderProps {
  isEditor?: boolean;
}

const Header: FC<IHeaderProps> = ({ isEditor = false }) => {
  const toggleEditing = useEditor((state) => state.toggleEditing);

  return (
    <header className={styles.header}>
      <div className={styles.left}>
        <Logo />
        {isEditor && <span>Website: ArtSmile</span>}
      </div>

      {isEditor && (
        <div className={styles.middle}>
          <Button onClick={toggleEditing} title="Edit" />
          <DevicePicker />
        </div>
      )}

      <div className={styles.right}>
        {isEditor && (
          <>
            <span>Saved</span>
            <div className={styles.buttons}>
              <Button title="Preview" isOutlined />
              <Button title="Publish" />
            </div>
          </>
        )}
        <UserMenu />
      </div>
    </header>
  );
};

export default Header;

