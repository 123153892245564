import React, { useState } from 'react';

import { useModal } from 'store/modal';
import { DASHBOARD_TABS } from 'components/Dashboard/const/tabs';
import Tabs from 'components/Dashboard/components/Tabs';
import { ETabs } from 'components/Dashboard/types/Tabs.enum';
import BasicInfoSection from 'components/Dashboard/components/BasicInfoSection';
import styles from './DashboardPage.module.scss';
import WebsiteSection from 'components/Dashboard/components/WebsiteSection';
import PaymentSection from 'components/Dashboard/components/PaymentSection';
import BillingHistorySection from 'components/Dashboard/components/BillingHistorySection';

const DashboardPage = () => {
  const [activeTab, setActiveTab] = useState(DASHBOARD_TABS[0]);

  const modal = useModal();
  const Modal = modal.component;

  const handleTabChange = (tab: ETabs) => setActiveTab(tab);

  return (
    <div className={styles.dashboard}>
      <Tabs activeTab={activeTab} onTabChange={handleTabChange} />
      <div className={styles.dashboard__content}>
        <div className={styles['dashboard__content--group']}>
          <BasicInfoSection isActive={activeTab === ETabs.BASIC_INFO} />
          <WebsiteSection
            isActive={activeTab === ETabs.YOUR_WEBSITE}
            websiteName="ArtSmile"
          />
        </div>
        <PaymentSection isActive={activeTab === ETabs.PAYMENT_METHODS} />
        <BillingHistorySection isActive={activeTab === ETabs.BILLING_HISTORY} />
      </div>

      {Modal && <Modal />}
    </div>
  );
};

export default DashboardPage;

